import React from "react";
import { Box, Slider, styled } from "@mui/material";

const WhiteSlider = styled(Slider)(({ theme, size }) => ({
  "& .MuiSlider-thumb": {
    color: "#B11A27",
    height: size === 'small' ? 12 : 17,
    width: size === 'small' ? 12 : 17//  // Change the thumb color
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#D9D9D9", // Change the unslided area color
  },
  "& .MuiSlider-track": {
    backgroundColor: "#B11A27", // Change the filled area color
  },
}));

const HorizontalSlider = (props) => {
  return (
    <Box height={props.height} className={props.className} display={props.display === undefined || props.display ? 'block' : 'none'} width={props.width} p={props.p}>
      <WhiteSlider
        marks={props.marks}
        size={props.size}
        orientation={props.orientation}
        value={props.value}
        min={props.min}
        max={props.max}
        onChange={props.onChange}
        valueLabelDisplay={props.label === undefined ? 'off' : props.label }
      />
    </Box>
  );
};

export default HorizontalSlider;
