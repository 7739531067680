import React from "react";
import { FormControlLabel } from "@material-ui/core";

import "../CSS/index.css";

import { Checkbox, styled } from "@mui/material";

const CheckBoxStyled = styled(Checkbox)(({theme})=>({
    color:'dark',
    '&.Mui-checked':{
        color:'#5470FF'
    }
}))
const CustomCheckBox =(props)=>{
    return (
        <>
        <FormControlLabel  value="end" className="props.className" control={<CheckBoxStyled  checked={props.checked} onChange={props.onChange}/>} 
        label={<h6 style={props.style}>{props.name}</h6>} />
        </>
    )
}

export default CustomCheckBox;