import React, { useContext } from "react";
import AppBar from "../../AppBar/AppBar";
import { Box, Stack, Drawer } from "@mui/material";
import CustomCheckBox from "../../customComponent/CustomCheckBox";
import { Context } from "../../App";
import "../../CSS/Home.css";
import "../../CSS/ValuePage.css";
import { useDispatch, useSelector } from "react-redux";
import {
  ModelDrawers,
  ModelDTC,
  ModelECWT,
  ModelGlobalPower,
  ModelIPLVAir,
  ModelIPLVWater,
  ModelIPLVWT,
  ModelOilDegradation,
  ModelOilDegradationVideo,
  ModelPlantEff,
  ModelUSDKWR,
  ModelUSDTon,
  ModelGMS,
} from "../../Redux/Components/ValueTool/ValueToolSlice";
import Scrollbars from "react-custom-scrollbars";
import SmallContent from "./SmallContent";
import USDperKw from "./USDperKw";

const Valuepage = () => {
  const {
    Drawers,
    OilDegradation,
    OilDegradationVideo,
    GlobalPower,
    IPLVWater,
    IPLVAir,
    ECWT,

    PlantEff,
    GMSingapore,
    IPLVWT,
    USDKWR,
    USDTon,

    DTC,
  } = useSelector((store) => store.valueTool);

  const dispatch = useDispatch();

  return (
    <>
      <Box className="ScreenBox2">
        <AppBar />
        <Box display={"flex"} justifyContent={"center"} gap={2} m={"1% 2%"}>
          <Box display={"flex"} bgcolor={"#ED1C2C"} gap={2} p={"0% 2%"}>
            <h2 className="PageTitle" style={{ color: "white" }}>
              Value Proposition
            </h2>{" "}
          </Box>
        </Box>
        <Box className="Value-Page-Main-Box">
          <Box display={{ xs: "none", md: "block" }} width={{ md: "25%" }}>
            <Box className="Value-Page-Box-1">
              <Scrollbars
                autoHide={false}
                style={{ width: "100%"}}
              >
                <CustomCheckBox
                  name={"Oil Degradation"}
                  checked={OilDegradation}
                  onChange={(e) =>
                    dispatch(ModelOilDegradation(!OilDegradation))
                  }
                />
                <br />
                <CustomCheckBox
                  name={"Oil Degradation Video"}
                  checked={OilDegradationVideo}
                  onChange={(e) =>
                    dispatch(ModelOilDegradationVideo(!OilDegradationVideo))
                  }
                />
                <br />
                <CustomCheckBox
                  name={"Global Power Price per Kw"}
                  checked={GlobalPower}
                  onChange={(e) => dispatch(ModelGlobalPower(!GlobalPower))}
                />
                <br />
                <CustomCheckBox
                  name={"IPLV Water-cooled"}
                  checked={IPLVWater}
                  onChange={(e) => dispatch(ModelIPLVWater(!IPLVWater))}
                />
                <br />
                <CustomCheckBox
                  name={"IPLV Air-cooled"}
                  checked={IPLVAir}
                  onChange={(e) => dispatch(ModelIPLVAir(!IPLVAir))}
                />
                <br />
                <CustomCheckBox
                  name={"GMS Singapore"}
                  checked={GMSingapore}
                  onChange={(e) => dispatch(ModelGMS(!GMSingapore))}
                />
                <br />
                <CustomCheckBox
                  name={"Impact on ECWT on COP"}
                  checked={ECWT}
                  onChange={(e) => dispatch(ModelECWT(!ECWT))}
                />
                <br />

                <CustomCheckBox
                  name={"Plant Efficiency"}
                  checked={PlantEff}
                  onChange={(e) => dispatch(ModelPlantEff(!PlantEff))}
                />
                <br />
                <CustomCheckBox
                  name={"IPLV Weighting"}
                  checked={IPLVWT}
                  onChange={(e) => dispatch(ModelIPLVWT(!IPLVWT))}
                />
                <br />
                <CustomCheckBox
                  name={"USD $/Kwr"}
                  checked={USDKWR}
                  onChange={(e) => dispatch(ModelUSDKWR(!USDKWR))}
                />
                <br />
                <CustomCheckBox
                  name={"USD $/ton"}
                  checked={USDTon}
                  onChange={(e) => dispatch(ModelUSDTon(!USDTon))}
                />
                <br />
                <CustomCheckBox
                  name={"DTC History"}
                  checked={DTC}
                  onChange={(e) => dispatch(ModelDTC(!DTC))}
                />
              </Scrollbars>
            </Box>
          </Box>

          <Box className="Value-page-box-2" width={{ md: "70%", xs: "100%" }}>
            <Box display={{ xs: "block", md: "none" }}>
              <Drawer
                anchor="left"
                open={Drawers}
                onClose={(e) => dispatch(ModelDrawers(!Drawers))}
              >
                <Box
                  p={2}
                  paddingBottom={20}
                  role="presentation"
                  bgcolor={"white"}
                  sx={{
                    width: {
                      xs: "250px",
                      sm: "250px",
                      md: "400px",
                      lg: "400px",
                    },
                  }}
                >
                  <Stack direction={"column"} spacing={0}>
                    <CustomCheckBox
                      name={"Oil Degradation"}
                      checked={OilDegradation}
                      onChange={(e) =>
                        dispatch(ModelOilDegradation(!OilDegradation))
                      }
                    />

                    <CustomCheckBox
                      name={"Oil Degradation Video"}
                      checked={OilDegradationVideo}
                      onChange={(e) =>
                        dispatch(ModelOilDegradationVideo(!OilDegradationVideo))
                      }
                    />

                    <CustomCheckBox
                      name={"Global Power Price per Kw"}
                      checked={GlobalPower}
                      onChange={(e) => dispatch(ModelGlobalPower(!GlobalPower))}
                    />

                    <CustomCheckBox
                      name={"IPLV Water-cooled"}
                      checked={IPLVWater}
                      onChange={(e) => dispatch(ModelIPLVWater(!IPLVWater))}
                    />

                    <CustomCheckBox
                      name={"IPLV Air-cooled"}
                      checked={IPLVAir}
                      onChange={(e) => dispatch(ModelIPLVAir(!IPLVAir))}
                    />
                    <CustomCheckBox
                      name={"GMS Singapore"}
                      checked={GMSingapore}
                      onChange={(e) => dispatch(ModelGMS(!GMSingapore))}
                    />
                    <CustomCheckBox
                      name={"Impact on ECWT on COP"}
                      checked={ECWT}
                      onChange={(e) => dispatch(ModelECWT(!ECWT))}
                    />

                    <CustomCheckBox
                      name={"Plant Efficiency"}
                      checked={PlantEff}
                      onChange={(e) => dispatch(ModelPlantEff(!PlantEff))}
                    />

                    <CustomCheckBox
                      name={"IPLV Weighting"}
                      checked={IPLVWT}
                      onChange={(e) => dispatch(ModelIPLVWT(!IPLVWT))}
                    />

                    <CustomCheckBox
                      name={"USD $/Kwr"}
                      checked={USDKWR}
                      onChange={(e) => dispatch(ModelUSDKWR(!USDKWR))}
                    />

                    <CustomCheckBox
                      name={"USD $/ton"}
                      checked={USDTon}
                      onChange={(e) => dispatch(ModelUSDTon(!USDTon))}
                    />

                    <CustomCheckBox
                      name={"DTC History"}
                      checked={DTC}
                      onChange={(e) => dispatch(ModelDTC(!DTC))}
                    />
                  </Stack>
                </Box>
              </Drawer>
            </Box>
            <Box display={{ xs: "block", md: "none" }} p={"2% 0 5% 0"}>
              <button
                onClick={(e) => dispatch(ModelDrawers(!Drawers))}
                className="Value-Button"
              >
                <h3 style={{ color: "#ED1C2C" }}>Open Drawer</h3>
              </button>
            </Box>
            <SmallContent
              value={OilDegradation}
              context={
                <img
                  width={"100%"}
                  src="../images/OilDeg.png"
                  alt="WATER COOLED"
                />
              }
            />
            <SmallContent
              value={OilDegradationVideo}
              context={
                OilDegradationVideo === false ? null : (
                  <video autoPlay={true} loop={false} controls={true}>
                    <source
                      src="https://res.cloudinary.com/dk3mlofmi/video/upload/v1655730715/turbo_wcRN35UL_uk0mbx.mp4"
                      type="video/mp4"
                    />
                  </video>
                )
              }
            />
            <SmallContent value={GlobalPower} context={<USDperKw />} />

            <SmallContent
              value={IPLVWater}
              context={
                <img
                  width={"100%"}
                  src="../images/IPLVWater.png"
                  alt="WATER COOLED"
                />
              }
            />
            <SmallContent
              value={IPLVAir}
              context={
                <img
                  width={"100%"}
                  src="../images/IPLVAir.png"
                  alt="AIR COOLED"
                />
              }
            />
            <SmallContent
              value={ECWT}
              context={
                <img
                  width={"100%"}
                  src="../images/ECWTCop.png"
                  alt="ECTW COP"
                />
              }
            />

            <SmallContent
              value={PlantEff}
              context={
                <img
                  width={"100%"}
                  src="../images/PlantEff.png"
                  alt="Plant EFF"
                />
              }
            />
                 <SmallContent
              value={GMSingapore}
              context={
                <img
                  width={"100%"}
                  src="../images/GMS.png"
                  alt="Singapore"
                />
              }
            />

            <SmallContent
              value={USDKWR}
              context={
                <img
                  width={"100%"}
                  src="../images/USDperKW.png"
                  alt="USD KWR"
                />
              }
            />
            <SmallContent
              value={USDTon}
              context={
                <img
                  width={"100%"}
                  src="../images/USDperTon.png"
                  alt="USD TON"
                />
              }
            />

            <SmallContent
              value={IPLVWT}
              context={
                <img width={"100%"} src="../images/IPLVWT.png" alt="IPLVWT" />
              }
            />
            <SmallContent
              value={DTC}
              context={
                <img width={"100%"} src="../images/History.png" alt="DTC" />
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Valuepage;

// <AppBar headline={
//     <h1 className="headingStyle" style={{textAlign:'center'}}>
// Value Proposition
//     </h1>
// } />

// <Box className="Value-Page">

// <ValueContent/>
// </Box>
