import React from "react";
import "../../CSS/index.css";
import { Grid, Box, Stack } from "@mui/material";
import CustomCheckBox from "../../customComponent/CustomCheckBox";
import "../../CSS/HydroPage.css";
import { useDispatch, useSelector } from "react-redux";
import {
  HydroCapacitySlider,
  ModelPIBCV,
  HydroChillerSlider,
  HydroPerKWSlider,
  HydroPerHourSlider,
  SlideSlider7_1,
  ModelVSDPUMP,
  ModelHPPMetric,
  SlideSlider7_3,
  SlideSlider6_1,
  SlideSlider6,
  SlideSlider6_20,
  SlideSlider6_21,
  SlideSlider6_18,
  SlideSlider6_19,
} from "../../Redux/Components/HydroPlant/HydroPlantSlice";
import AppBar from "../../AppBar/AppBar";
import HorizontalSlider from "../../customComponent/HorizontalSlider";
import BlueSlider from "../../customComponent/BlueHorizontalSlider";
import CustomSlider from "../../customComponent/Customslider";

const HydroPage = () => {
  const {
    Hydrocapacity,
    PIBCV,
    VSDPumps,
    Hydrochiller,
    HydroPerKW,
    HydroPerHour,
    slider6_18,
    slider6_19,
    slider6_21,
    slider6_20,
    slider6_1,
    slider6,
    slider7_1,
    Hppmetric,
    slider7_3,
  } = useSelector((store) => store.hydroplant);
  const dispatch = useDispatch();

  //Formula for Hydrolic pages
  const label44_9 = Hydrocapacity;
  const label44_16 = Hydrocapacity;
  const label44_13 = HydroPerKW / 100;
  const label44_15 = HydroPerHour;
  const label44_14 = Hydrochiller / 100;
  const label54 = slider6 / 100;
  const label60_1 = slider6 / 100;
  const label55 = slider7_3 / 100;
  const label56 = slider7_3 / 100;
  const label54_1 = slider6_1 / 100;
  const label85 = 0.042205;
  const label80_1 = slider7_1 / 100;

  const label48_34 = Math.round(label44_9 * 3.5);

  const label48_54 = Math.round(label48_34 * label85) + slider6_20;
  const label97 = Math.round(label48_54 * 0.75);
  const label102 = Math.round(label48_54 * 15.85);
  const label48_55 = (label48_34 / 100 + 4 + slider6_21).toFixed(2);
  const label48_55infeet = (label48_55 * 3.28084).toFixed(2);
  const label53_1 = Math.floor(label48_55 * 0.102);

  const label48_57 = Math.round(label48_34 * 0.054) + slider6_18;
  const label98 = Math.round(label48_57 * 0.8);
  const label105 = Math.round(label98 * 15.85);

  const label48_58 = parseFloat(label48_55 * 0.8 + slider6_19).toFixed(2);
  const label48_58infeet = (label48_58 * 3.28084).toFixed(2);
  const label63_1 = Math.round(label48_58 * 0.102);
  const label48_41 = (label48_57 * 90) / 100;
  const label48_49 = Math.pow(label48_41 / label48_57, 2) * label48_58;
  const label48_53 =
    (label48_41 * 1000 * 9.81 * label48_49) /
    (Math.pow(10, 6) * (80 / 100) * (69 / 100));
  const label48_38 =
    (label48_54 * 1000 * 9.81 * label48_55) /
    ((Math.pow(10, 6) * (90 / 100) * 79.5) / 100);
  const label48_45 = label48_38 * (label44_15 * 0.1);
  const label48_36 = (label48_54 * 80) / 100;
  const label48_44 = Math.pow(label48_36 / label48_54, 2) * label48_55;
  const label48_47 =
    (label48_36 * 1000 * 9.81 * label48_44) /
    (Math.pow(10, 6) * (68 / 100) * (59 / 100));
  const label48_50 = label48_47 * (label44_15 * 0.6);
  const label48_35 = (label48_54 * 90) / 100;
  const label48_40 = Math.pow(label48_36 / label48_35, 2) * label48_55;
  const label48_42 =
    (label48_35 * 1000 * 9.81 * label48_40) /
    (Math.pow(10, 6) * (80 / 100) * (69 / 100));

  const label48_48 = label48_42 * (label44_15 * 0.3);

  const label48_30 = label48_45 + label48_50 + label48_48;
  const label48_51 =
    (label48_57 * 1000 * 9.81 * label48_58) /
    (Math.pow(10, 6) * (90 / 100) * (80 / 100));
  const label48_37 = label48_51 * (label44_15 * 0.2);
  const label48_39 = label48_53 * (label44_15 * 0.4);
  const label48_46 = (label48_57 * 80) / 100;
  const label48_52 = Math.pow(label48_46 / label48_57, 2) * label48_58;
  const label48_56 =
    (label48_46 * 1000 * 9.81 * label48_52) /
    (Math.pow(10, 6) * (68 / 100) * (59 / 100));
  const label48_43 = label48_56 * (label44_15 * 0.4);
  const label48_59 = label48_37 + label48_39 + label48_43;

  const label48_29 = Math.round(label48_45 + label48_50 + label48_48);

  //ChWp Opex per year // big problem
  const label49_5 = Math.round(label48_29 * label44_13);

  //Fan Opex per year
  const label50_1 = Math.round(label49_5 * 0.32);

  const label84 = slider7_1 / 100;
  const label55_2 = Math.round(label50_1 / label44_13) * label84;
  const label61_2 = parseFloat(12 / (label44_14 * 3.412)).toFixed(2);
  const label59_7 = (label48_34 / label61_2) * label44_15;
  const label88 = label48_30 + label48_59 + label55_2 + label59_7;
  const label59_9 = label48_34 * label44_15;
  const label89 = (label59_9 / label88).toFixed(2);
  const label48_32 = Math.round(label48_37 + label48_39 + label48_43);
  const label49_7 = Math.round(label48_32 * label44_13);
  const label44_8 = Math.round(
    label44_16 * label44_14 * label44_15 * label44_13 * label55
  );

  // Total Convo Setup
  const label86 = label49_5 + label49_7 + label44_8 + label50_1;
  const label92 = VSDPumps === true ? label48_30 : label48_30;

  //ChWP Opex per year
  const label49_9 = Math.round(label92 * label44_13 * label54_1);
  const label96 = label48_59;
  const label44_17 = Math.round(
    label44_16 *
      label44_14 *
      label44_15 *
      label44_13 *
      label80_1 *
      label55 *
      label56
  );
  const label50_2 = Math.round(label49_9 * 0.32);
  const label49_10 = Math.round(label96 * label44_13 * label54);

  //Danfoss PIBC Setup
  const label87 = label44_17 + label49_9 + label50_2 + label49_10;

  const label60 = slider6_1 / 100;
  const label106 = parseFloat(
    (label48_29 / label44_15 / label44_9) * label60
  ).toFixed(2);
  const label108 = parseFloat(
    (label48_32 / label44_15 / label44_9) * label60_1
  ).toFixed(3);
  const label55_1 = Math.round(label50_1 / label44_13);
  const label59_8 = label59_7 + label48_29 + label48_32 + label55_1;
  const label60_3 = parseFloat(label59_9 / label59_8).toFixed(2);
  const label62_3 = parseFloat(12 / (label89 * 3.412)).toFixed(2);

  const label82 = label44_14 * label80_1;

  //plant cop formula
  const label50 = label44_9 * label82;
  const label61 = label44_9 * label108;
  const label51 = label44_9 * label106;

  const label76 = (label50 + label51 + label61) / label44_9;
  const label100 = parseFloat(label76).toFixed(2);
  const label99 = parseFloat(12 / label76 / 3.412).toFixed(2);

  const label62 = slider7_3 - 107;
  const label63 = label62 * -1;
  const label59 = label62 * -1 + 5;

  return (
    <>
      <Box className="ScreenBox2">
        <AppBar />

        <Grid container spacing={2} className="hydro-title-box">
          <Grid item md={5} xs={12} sm={12}>
            <Box
              sx={{ backgroundColor: "#ED1C2C" }}
              display={"flex"}
              flexDirection={"row"}
              justifyContent="center"
            >
              <h1 className="PageTitle" style={{ color: "white" }}>
                Hydronic Plant Performance
              </h1>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={9} sm={9} md={9}>
                <Box
                  display={"flex"}
                  p={"4% 4% 2% 4%"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                >
                  <CustomCheckBox
                    name={"PIBCV"}
                    checked={PIBCV}
                    onChange={(event) => dispatch(ModelPIBCV(!PIBCV))}
                  />
                  <CustomCheckBox
                    name={`VSD Pumps`}
                    checked={VSDPumps}
                    onChange={(event) => dispatch(ModelVSDPUMP(!VSDPumps))}
                  />
                  <CustomCheckBox
                    name={"Metric"}
                    checked={Hppmetric}
                    onChange={(event) => dispatch(ModelHPPMetric(!Hppmetric))}
                  />
                </Box>
                <Box
                  display={"flex"}
                  p={"4% 4% 2% 4%"}
                  flexDirection={"row"}
                >
                  <h5 style={{color:'red', fontWeight:'bolder'}}>
                    Green Mark Certification Singapore
                  </h5>
                </Box>
                <Stack direction={"row"} p={"2% 4%"} gap={1}>
                  <Box display={label62_3 <= 0.67 ? "block" : "none"}>
                    <h5 style={{ color: "black", fontWeight: "600" }}>
                      Super Low Energy(SLE)
                    </h5>
                  </Box>

                  <Box display={label62_3 <= 0.74 ? "block" : "none"}>
                    <h5 style={{ fontWeight: "600", color: "black" }}>
                      Platinum
                    </h5>
                  </Box>

                  <Box display={label62_3 <= 0.79 ? "block" : "none"}>
                    <h5 style={{ fontWeight: "600", color: "blue" }}>
                      EnergyPLUS
                    </h5>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={3} sm={3} md={3} mt={"2%"}>
                {PIBCV && (
                  <Box className="image-pibv-box">
                    <img src="../images/PIBV.png" />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={7} xs={12} sm={12}>
            <Box className="image-container1">
              <img src="../images/power.png" className="power-image" />

              <div className="Typo6">
                <h5 style={{ fontSize: "12px" }}> {label59} c ECHW </h5>
              </div>
              <div className="Typo7">
                <h5 style={{ fontSize: "12px" }}> 5 c LCHW</h5>
              </div>
              <div className="Typo8">
                <h5 style={{ fontSize: "12px" }}>{label63} c Delta T </h5>
              </div>
              <Box className="by-pass-valve">
                <Stack
                  direction={"row"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <h5 style={{ fontSize: "12px", color: "#1F21FF" }}>Close</h5>
                  <h5 style={{ fontSize: "12px", color: "#1F21FF" }}>Open</h5>
                </Stack>
                <HorizontalSlider
                  size={"small"}
                  value={slider7_3}
                  min={100}
                  max={103}
                  onChange={(e) => dispatch(SlideSlider7_3(e.target.value))}
                />
                <h5 style={{ fontSize: "12px" }}>ByPass Valve</h5>
              </Box>

              <Box className="LeftRpm">
                <Stack direction={"row"} width={50}>
                  <Box display={VSDPumps === true ? "block" : "none"}>
                    {" "}
                    <h5 style={{ fontSize: "12px" }}>KWRT {label106}</h5>
                  </Box>
                  <HorizontalSlider
                    display={VSDPumps}
                    orientation={"vertical"}
                    size={"small"}
                    height={85}
                    width={34}
                    value={slider6_1}
                    min={16}
                    max={100}
                    onChange={(e) => dispatch(SlideSlider6_1(e.target.value))}
                  />
                  <Stack direction={"column"} gap={2}>
                    <Box display={VSDPumps === true ? "block" : "none"}>
                      {" "}
                      <h5 style={{ fontSize: "12px", color: "#1F21FF" }}>
                        Max RPM
                      </h5>
                    </Box>
                    <Box display={VSDPumps === true ? "block" : "none"}>
                      {" "}
                      <h5 style={{ fontSize: "12px", color: "#1F21FF" }}>
                        Min RPM
                      </h5>
                    </Box>
                  </Stack>
                  <Box display={VSDPumps === true ? "block" : "none"}>
                    <img
                      className="DriveTest1"
                      src="../images/Drivetest1.png"
                      alt="Drive Test"
                    />
                  </Box>
                </Stack>
              </Box>
              <Box className="RightRpm">
                <Stack direction={"row"} width={50}>
                  <Box display={VSDPumps === true ? "block" : "none"}>
                    {" "}
                    <h5 style={{ fontSize: "12px" }}>KWRT {label108}</h5>
                  </Box>
                  <HorizontalSlider
                    display={VSDPumps}
                    orientation={"vertical"}
                    size={"small"}
                    height={85}
                    width={34}
                    value={slider6}
                    min={16}
                    max={100}
                    onChange={(e) => dispatch(SlideSlider6(e.target.value))}
                  />
                  <Stack direction={"column"} justifyContent={"space-around"}>
                    <Box display={VSDPumps === true ? "block" : "none"}>
                      {" "}
                      <h5 style={{ fontSize: "12px", color: "#1F21FF" }}>
                        Max RPM
                      </h5>
                    </Box>
                    <Box display={VSDPumps === true ? "block" : "none"}>
                      {" "}
                      <h5 style={{ fontSize: "12px", color: "#1F21FF" }}>
                        Min RPM
                      </h5>
                    </Box>
                  </Stack>
                  <Box display={VSDPumps === true ? "block" : "none"}>
                    <img
                      className="DriveTest2"
                      src="../images/Drivetest1.png"
                      alt="Drive Test"
                    />
                  </Box>
                </Stack>
              </Box>

            </Box>
          </Grid>
        </Grid>
        <Box className="Hydro-Box">
          <Grid container>
            <Grid item md={4} sm={6} xs={12} m={{ xs: "3% 0", md: "0" }}>
              <Stack direction={"column"} display={"flex"}>
                <Box display={PIBCV === true ? "block" : "none"}>
                  <Box className="Hydro-slider-box1">
                    <HorizontalSlider
                      label={"off"}
                      min={80}
                      max={100}
                      value={slider7_1}
                      onChange={(e) => dispatch(SlideSlider7_1(e.target.value))}
                    />
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      pb={"2%"}
                    >
                      <h5>50%</h5>
                      <h5>75%</h5>
                      <h5>100%</h5>
                    </Box>
                    <h3
                      style={{
                        textAlign: "center",
                        color: "#DC143C",
                        fontWeight: "600",
                      }}
                    >
                      Chiller Load %
                    </h3>
                  </Box>
                </Box>
                <Box className="Hydro-slider-box2">
                  <CustomSlider
                  className={'AdjustableSlider2'}
                    SliderName={"Capacity Adj"}
                    
                    boxwidth={"25%"}
                    value={Hydrocapacity}
                    min={100}
                    max={1000}
                    onChange={(e) =>
                      dispatch(HydroCapacitySlider(e.target.value))
                    }
                    unitname={"Ton"}
                    bottomvalueDispley={Hppmetric}
                    unitvalue={Hydrocapacity}
                    BottomName={"Kwr"}
                    BottomValue={Math.round(Hydrocapacity * 3.5)}
                  />
                  <CustomSlider
                    SliderName={"Chiller COP"}
                    
                    className={'AdjustableSlider2'}
                    unitname={"Kw/Tn"}
                    boxwidth={"25%"}
                    value={Hydrochiller}
                    bottomvalueDispley={Hppmetric}
                    min={25}
                    max={99}
                    onChange={(e) =>
                      dispatch(HydroChillerSlider(e.target.value))
                    }
                    BottomName={"Kw/Kw"}
                    BottomValue={label61_2}
                    unitvalue={Hydrochiller / 100}
                  />
                  <CustomSlider
                    SliderName={"$ per KW"}
                    className={'AdjustableSlider2'}
                    boxwidth={"25%"}
                    value={HydroPerKW}
                    onChange={(e) => dispatch(HydroPerKWSlider(e.target.value))}
                    min={1}
                    max={30}
                    unitvalue={HydroPerKW / 100}
                  />
                  <CustomSlider
                    SliderName={"Run Hours / Year"}
                    className={'AdjustableSlider2'}
                    boxwidth={"25%"}
                    value={HydroPerHour}
                    min={0}
                    max={8760}
                    onChange={(e) =>
                      dispatch(HydroPerHourSlider(e.target.value))
                    }
                    unitvalue={HydroPerHour}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <Box className="Hydro-Box-3" >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  style={{ backgroundColor: "#ECF0F5" }}
                  className="Hydro-Box-3-1"
                >
                  <h5
                    style={{
                      paddingBottom: "0.3rem",
                      paddingTop: "0.3rem",
                      fontSize: "13px",
                    }}
                  >
                    Chilled Water
                  </h5>
                  <h6 style={{ marginBottom: "-3px", fontSize: "12px" }}>
                    Flow USGPM
                  </h6>
                  <Box display={"flex"} flexDirection={"row"} gap={1}>
                    <h5
                      style={{
                        color: "#ED1C2C",
                        marginTop: "2%",
                        width: "20%",
                      }}
                    >
                      {label102}
                    </h5>
                    <BlueSlider
                      max={30}
                      min={-20}
                      value={slider6_20}
                      onChange={(e) =>
                        dispatch(SlideSlider6_20(e.target.value))
                      }
                      display={true}
                      width={"70%"}
                    />
                  </Box>
                  {Hppmetric && (
                    <>
                      <h6
                        style={{
                          color: "#5B5B5B",
                          marginTop: "-10px",
                          fontSize: "11px",
                        }}
                      >
                        Flow L/S
                      </h6>
                      <h6 style={{ color: "#5B5B5B", fontSize: "11px" }}>
                        {label48_54}
                      </h6>
                    </>
                  )}
                  <Box className="gapBox"></Box>
                  <h5
                    style={{
                      marginBottom: "-5px",
                      paddingTop: "0.3rem",
                      fontSize: "12px",
                    }}
                  >
                    {Hppmetric === true ? "Head in mts" : "Head in ft"}
                  </h5>
                  <Box display={"flex"} flexDirection={"row"} gap={1}>
                    <h5
                      style={{
                        color: "#ED1C2C",
                        marginTop: "2%",
                        width: "20%",
                      }}
                    >
                      {Hppmetric === true ? label48_55 : label48_55infeet}
                    </h5>
                    <BlueSlider
                      max={30}
                      min={-20}
                      value={slider6_21}
                      onChange={(e) =>
                        dispatch(SlideSlider6_21(e.target.value))
                      }
                      display={true}
                      width={"70%"}
                    />
                  </Box>

                  <h6
                    style={{
                      color: "#5B5B5B",
                      marginTop: "-10px",
                      fontSize: "11px",
                    }}
                  >
                    KPA
                  </h6>
                  <h6 style={{ color: "#5B5B5B", fontSize: "11px" }}>
                    {label53_1}
                  </h6>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  style={{ backgroundColor: "#ECF0F5" }}
                  className="Hydro-Box-3-2"
                >
                  <h5
                    style={{
                      marginBottom: "0.3rem",
                      paddingTop: "0.3rem",
                      fontSize: "13px",
                    }}
                  >
                    Condenser Water
                  </h5>
                  <h5 style={{ marginBottom: "-3px", fontSize: "12px" }}>
                    Flow USGPM
                  </h5>
                  <Box display={"flex"} flexDirection={"row"} gap={1}>
                    <h5
                      style={{
                        color: "#ED1C2C",
                        marginTop: "2%",
                        width: "20%",
                      }}
                    >
                      {label105}
                    </h5>
                    <BlueSlider
                      max={30}
                      min={-20}
                      value={slider6_18}
                      onChange={(e) =>
                        dispatch(SlideSlider6_18(e.target.value))
                      }
                      display={true}
                      width={"70%"}
                    />
                  </Box>
                  {Hppmetric && (
                    <>
                      <h6
                        style={{
                          color: "#5B5B5B",
                          marginTop: "-10px",
                          fontSize: "11px",
                        }}
                      >
                        Flow L/S
                      </h6>
                      <h6 style={{ color: "#5B5B5B", fontSize: "11px" }}>
                        {label48_57}
                      </h6>
                    </>
                  )}
                   <Box className="gapBox"></Box>
                  <h5
                    style={{
                      marginBottom: "-5px",
                      paddingTop: "0.3rem",
                      fontSize: "12px",
                    }}
                  >
                    {Hppmetric === true ? "Head in mts" : "Head in ft"}
                  </h5>
                  <Box display={"flex"} flexDirection={"row"} gap={1}>
                    <h5
                      style={{
                        color: "#ED1C2C",
                        marginTop: "2%",
                        width: "20%",
                      }}
                    >
                      {Hppmetric === true ? label48_58 : label48_58infeet}
                    </h5>
                    <BlueSlider
                      max={30}
                      min={-10}
                      value={slider6_19}
                      onChange={(e) =>
                        dispatch(SlideSlider6_19(e.target.value))
                      }
                      display={true}
                      width={"70%"}
                    />
                  </Box>

                  <h6
                    style={{
                      color: "#5B5B5B",
                      marginTop: "-10px",
                      fontSize: "11px",
                    }}
                  >
                    KPA
                  </h6>
                  <h6 style={{ color: "#5B5B5B", fontSize: "11px" }}>
                    {label63_1}
                  </h6>
                </Box>
              </Box>
            </Grid>
            <Grid item md={5} xs={12} sm={12} m={{ xs: "3% 0", md: "0" }}>
              <Stack direction={"column"}>
                <Box className="Hydro-Box-4">
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    pb={"0.4rem"}
                    justifyContent={"flex-end"}
                  >
                    {PIBCV && <h6>Danfoss Hydronic Optimise</h6>}
                  </Stack>
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    justifyContent={"space-around"}
                  >
                    <Stack direction={"column"} gap={2} width={"30%"}>
                      <h6>Chiller Per year </h6>
                      <h6>CHWP Opex Per Year</h6>
                      <h6>CWP Opex Per Year</h6>
                      <h6>Fan Opex Per year</h6>
                    </Stack>
                    <Stack direction={"column"} gap={2} width={"20%"}>
                      <h6>${label44_8}</h6>
                      <h6>$ {label49_5}</h6>
                      <h6>$ {label49_7}</h6>
                      <h6>$ {label50_1}</h6>
                    </Stack>
                    {PIBCV && (
                      <Stack direction={"column"} gap={2} width={"20%"}>
                        <h6> $ {label44_17}</h6>
                        <h6>$ {label49_9}</h6>
                        <h6> $ {label49_10}</h6>
                        <h6>$ {label50_2}</h6>
                      </Stack>
                    )}
                  </Stack>
                </Box>
                <Box className="Hydro-Box-5">
                  <Stack direction={"column"} gap={1}>
                    <Stack direction={"row"} gap={4}>
                      <h3>Conventional Setup</h3>{" "}
                      <h3 style={{ color: "#1F21FF", fontWeight: "900" }}>-</h3>{" "}
                      <h3 style={{ color: "#1F21FF" }}>$ {label86}</h3>
                    </Stack>
                    <Stack direction={"row"} gap={2}>
                      {VSDPumps && <h3>Danfoss Optimise Setup</h3>}
                      <Box className="gapBox"></Box>
                      {VSDPumps && (
                        <h3 style={{ color: "#1F21FF", fontWeight: "900" }}>
                          -
                        </h3>
                      )}
                      {VSDPumps && (
                        <h3 style={{ color: "#1F21FF" }}>$ {label87}</h3>
                      )}
                    </Stack>
                  </Stack>
                </Box>
                <Box className="Hydro-Box-5">
                  <Stack direction={"row"} gap={4}>
                    <h3
                      style={{
                        fontSize: "calc(1rem + 0.2vw)",
                        fontWeight: "600",
                        width: "25%",
                      }}
                    >
                      Plant COP
                    </h3>{" "}
                    {Hppmetric && (
                      <h3
                        style={{
                          fontSize: "calc(1rem + 0.2vw)",
                          color: "#ED1C2C",
                          fontWeight: "600",
                          width: "10%",
                        }}
                      >
                        {label99}
                      </h3>
                    )}
                    <h3
                      style={{
                        fontSize: "calc(1rem + 0.2vw)",
                        color: "#ED1C2C",
                        fontWeight: "600",
                        width: "10%",
                      }}
                    >
                      {label100}
                    </h3>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default HydroPage;
