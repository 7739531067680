import React from 'react';
import '../CSS/Card.css';
import {  Link } from "react-router-dom";
const ImageDisplay = ({ images }) => {
    return (
        <div className="image-container">
            {images.map((image, index) => (
                <img key={index} src={image} alt={`Image ${index}`} className={`${images.length === 2 ? 'card__image--half' : 'card__image'}`} />
            ))}
        </div>
    );
}


const CustomCard = (props) => {
 
    return (
        <div className="card">
            <ImageDisplay images={props.img} />
            <div className="card__content">
                <h2 className="card__title">{props.name}</h2>
                <h6 className="card__description">
                   {props.details}
                </h6>
                <Link  underline="none" to={props.link}>
                <div className="card__arrow" >
                    <img src={"../images/Vector.png"} alt="Outer Vector" className="card__arrow-outer" />
                    <img src={"../images/arrowVector.png"} alt="Inner Vector" className="card__arrow-inner" />
                </div>
                </Link>
            </div>
        </div>
    );
}

export default CustomCard;
