import React from "react";
import { Box, Stack, Slider, styled } from "@mui/material";
import "../../CSS/index.css";
import CustomSlider from "../../customComponent/Customslider";
import CustomCheckBox from "../../customComponent/CustomCheckBox";
import "../../CSS/ToolBox.css";
import { useSelector, useDispatch } from "react-redux";
import {
  SlideSlider6_13,
  ModelLowLiftApp,
  SlideSlider6_12,
  
  ModelLowLiftMetric,
  SlideSlider6_8,
  SlideSlider6_9,
  SlideSlider6_10,
  SlideSlider6_11,
} from "../../Redux/Components/LowLift/LowLiftSlice";
import AppBar from "../../AppBar/AppBar";
import WhiteCheckBox from "../../customComponent/WhiteCheckBox";
import BlueSlider from "../../customComponent/BlueHorizontalSlider";
import HorizontalSlider from "../../customComponent/HorizontalSlider";

const RedSlider = styled(Slider)(({ theme, size }) => ({
  "& .MuiSlider-thumb": {
    color: "#B11A27",
    height: size === 'medium' ? 20 : 17,
    width: size === 'medium' ? 20 : 17//  // Change the thumb color
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#D9D9D9", // Change the unslided area color
  },
  "& .MuiSlider-track": {
    backgroundColor: "#B11A27", // Change the filled area color
  },
}));

const ToolBox = () => {
  const {
    slider6_8,
    slider6_9,
    slider6_10,
    slider6_12,
    slider6_13,
    slider6_11,
    LowLiftApp,
    LowLiftMetric,
  } = useSelector((state) => state.LowLift);
  const dispatch = useDispatch();

  const label44_4 = slider6_8;
  const label72 = 0.58;
  const label77 = 0.58;
  const label71 = slider6_13 - 29;
  const label64_4 = slider6_12;
  const label64_5 = slider6_13;
  const label44_11 = slider6_11;
  const label44_10 = slider6_9 / 100;
  const label44_5 = slider6_10 / 100;
  const label61_1 = (12 / (label44_10 * 3.412)).toFixed(2);
  const label48_33 = Math.round(label44_4 * 3.5);
  const label90 = Math.round(label64_4 * 1.8) + 32;
  const label68 = label64_4 - 5;
  const label90_2 = Math.round(label68 * 1.8) + 32;
  const label90_1 = Math.round(label64_5 * 1.8) + 32;
  const label64_6 = Math.round(label64_5 + 5.6);
  const label90_3 = Math.round(label64_6 * 1.8) + 32;
  const label115 = (slider6_12 / slider6_13) * 15;
  const label65 = slider6_12 / 100 - 0.11;
  const label44_12 = slider6_9 / 100 - label65 + 0.01;
  const label78 = (label71 * 2) / 100;
  const label71_1 = Math.round(
    label44_4 * label72 * label44_10 * label44_5 * label44_11
  );
  const label71_2 = Math.round(
    label44_4 * (label77 + label78) * label44_12 * label44_5 * label44_11
  );
  const label74 = label71_1 - label71_2;
  const label69 = label64_6 + 2;
  const label70 = label68 - 1;
  const label79 = label69 - label70;
  const labelCOP = (12/ label115 /3.412).toFixed(2);



  return (
    <>
      <Box className="ScreenBox2">
        <AppBar />
        <Box display={"flex"} justifyContent={"center"} gap={2} className="title-box-lowlift">
          <Box display={"flex"} bgcolor={"#ED1C2C"} gap={2} p={"0% 0.5% 0% 3%"}>
            <h2 className="PageTitle" style={{ color: "white" }}>
              Low Lift & High SST Application
            </h2>{" "}
            {
              <WhiteCheckBox
                checked={LowLiftApp}
                onChange={(e) => dispatch(ModelLowLiftApp(!LowLiftApp))}
              />
            }
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={{ md: "row", xs: "column" }}
          className="tool-box"
          gap={3}
        >
          <Box className="Tool-Box-1" width={{ md: "30%" }}>
            <CustomCheckBox
              style={{ fontSize: "0.9rem" }}
              checked={LowLiftMetric}
              name={"Metric"}
              onChange={(e) => dispatch(ModelLowLiftMetric(!LowLiftMetric))}
            />
            <h3> Chiller Opex Per year - $ {label71_1} </h3>
            {LowLiftApp && <h3>New Opex Per year - $ {label71_2}</h3>}
            {LowLiftApp && <h3>Saving Opex Per year - $ {label74}</h3>}
          </Box>
          <Box className="Tool-Box-2" width={{ md: "70%" }}>
            <Box className="image-container-2">
              <img src="../images/power.png" className="power-image-tool" />
              <div className="EWT">
                <h6 style={{ fontSize: "14px" }}>
                  {" "}
                  EWT{" "}
                  {LowLiftMetric === true
                    ? label64_4 + " °C"
                    : label90 + " °F"}{" "}
                </h6>{" "}
              </div>
              <div className="LWT">
                <h6 style={{ fontSize: "14px" }}>
                  {" "}
                  LWT{" "}
                  {LowLiftMetric === true
                    ? label68 + " °C"
                    : label90_2 + " °F"}{" "}
                </h6>
              </div>
              <div className="EWT2">
                <h6 style={{ fontSize: "14px" }}>
                  {" "}
                  EWT{" "}
                  {LowLiftMetric === true
                    ? label64_5 + " °C"
                    : label90_1 + " °F"}{" "}
                </h6>
              </div>
              <div className="LWT2">
                <h6 style={{ fontSize: "14px" }}>
                  {" "}
                  LWT{" "}
                  {LowLiftMetric === true
                    ? label64_6 + " °C"
                    : label90_3 + " °F"}{" "}
                </h6>
              </div>
              <Box
                display={slider6_12 > 19 ? "block" : "none"}
                className="High-SST"
              >
                <h6 style={{ color: "#DC143C" }}>
                  High SST Compressor {<br />} Version Recommended
                </h6>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", sm: "column", md: "row" }}
          className="Tool-Box-3-4"
        >
          <Box
            className="Tool-Box-3"
            width={{ md: "40%" }}
            m={{ xs: "2% 0", md: " 0 2%" }}
          >
            <CustomSlider
             
              SliderName={"Capacity"}
              boxwidth={"25%"}
              className ={'AdjustableSlider2'}
              min={100}
              max={1000}
              value={slider6_8}
              onChange={(e) => dispatch(SlideSlider6_8(e.target.value))}
              BottomName={"Kwr"}
              BottomValue={label48_33}
              unitname={"Ton"}
              unitvalue={slider6_8}
              bottomvalueDispley={LowLiftMetric}
            />
            <CustomSlider
              SliderName={"Chiller IPLV"}
              boxwidth={"25%"}
              className ={'AdjustableSlider2'}
              value={slider6_9}
              min={22}
              max={99}
              onChange={(e) => dispatch(SlideSlider6_9(e.target.value))}
              BottomName={"Kw/Kw"}
              BottomValue={label61_1}
              bottomvalueDispley={LowLiftMetric}
              unitvalue={label44_10}
              unitname={"Kw/Tn"}
            />
            <CustomSlider
              SliderName={"$ per KW"}
              className ={'AdjustableSlider2'}
              boxwidth={"25%"}
              value={slider6_10}
              min={1}
              max={30}
              onChange={(e) => dispatch(SlideSlider6_10(e.target.value))}
              unitvalue={slider6_10 / 100}
            />
            <CustomSlider
              SliderName={"Run Hours / Year"}
              boxwidth={"25%"}
              className ={'AdjustableSlider2'}
              value={slider6_11}
              min={1}
              max={8760}
              onChange={(e) => dispatch(SlideSlider6_11(e.target.value))}
              unitvalue={slider6_11}
            />
          </Box>
          <Box className="Tool-Box-4" width={{ md: "60%" }}>
            <Box display={LowLiftApp === true ? "block" : "none"}>
              <h3
                style={{
                  fontWeight: "500",
                 textAlign:'center',
                  fontSize: "1rem",
                }}
              >
                {" "}
                Coefficient of Performance
              </h3>
              <Box display={"flex"} flexDirection={"column"}>
                <Stack
                  direction={"row"}
                  display={"flex"}
                  marginTop={"2%"}
                  justifyContent={"space-between"}
                  marginBottom={"-0.2%"}
                >
                  <h5>0.7</h5>
                  <h5>0.35</h5>
                  <h5>0.17 Kw/Tn</h5>
                </Stack>

                <Box width={'100%'}>
                  <RedSlider size="medium" value={label115} onChange={false} min={3} max={25} />
                </Box>
                <Stack
                  direction={"row"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  marginTop={"-1%"}
                >
                  {LowLiftMetric && <h5>5.0</h5>}
                  {LowLiftMetric && <h5>10.0</h5>}
                  {LowLiftMetric && <h5>20.0 Kw/Kw</h5>}
                </Stack>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                width={"100%"}
                mt={"3%"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={0.5}
                  width={"70%"}
                > 
                  <Stack direction={'row'}>
                    <Box className="gapBox"></Box>
                    <Box width={'18%'}>

                    <h6 style={{paddingTop:'8px'}}>
                  EWT{" "}
                  {LowLiftMetric === true
                    ? label64_5 + " °C"
                    : label90_1 + " °F"}
                </h6>
                    </Box>
                 
                  <BlueSlider
                  width={'60%'}
                  label={'off'}
                    display={true}
                    value={slider6_13}
                    min={13}
                    max={35}
                    onChange={(e) => dispatch(SlideSlider6_13(e.target.value))}
                  />
                   <Box width={'20%'} >
                   <h6 style={{paddingTop:'8px', paddingLeft:'15%'}} >
                   LWT{" "}
                  {LowLiftMetric === true
                    ? label64_6 + " °C"
                    : label90_3 + " °F"}
                </h6>
                   </Box>
                  </Stack>
                  <h5 style={{ textAlign: "center", fontWeight:'bolder' }}>Condenser Water</h5>
                  <Stack direction={'row'} mt={'2%'}>
                    <Box width={'18%'}>
                    <Box className="gapBox" ></Box>
                    <h6 style={{paddingTop:'8px'}}>
                  EWT {" "}
                  {LowLiftMetric === true
                    ? label64_4 + " °C"
                    : label90 + " °F"}
                </h6>
                    </Box>
                    <BlueSlider
                    width={'60%'}
                  label={'off'}
                    display={true}
                    value={slider6_12}
                    min={8}
                    max={26}
                    onChange={(e) => dispatch(SlideSlider6_12(e.target.value))}
                  />
                    <Box width={'20%'}>
                    <h6 style={{ paddingTop:'8px', paddingLeft:'15%'}}>
                  LWT{" "}
                  {LowLiftMetric === true
                    ? label68 + " °C"
                    : label90_2 + " °F"}
                </h6>
                    </Box>
                  </Stack>
                  <h6 style={{ textAlign: "center",fontWeight:'bolder' }}>Chilled Water</h6>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  width={"30%"}
                  justifyContent={"space-between"}
                  p={"0 2% "}
                >
                  <Box
                    display={label79 < 13 ? "block" : "none"}
                    width={"100%"}
                    mt={"-20px"}
                    height={"100%"}
                  >
                    <img src="../images/lowlift.png" width={"63%"} />
                  </Box>
                  <Box
                    display={label79 < 13 ? "block" : "none"}
                    mt={"5%"}
                    width={"20%"}
                  >
                    <h6 style={{color: "red" }}>
                      Low Lift Required
                    </h6>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ToolBox;
