import React from 'react';
import { Box, Stack, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { StyledButton } from '../../customComponent/StyledButton';
import "./../../CSS/Home.css";
import "../../CSS/index.css";
import AppBar from '../../AppBar/AppBar';


const LandingScreen = () => {
  return (
   <Box className='ScreenBox'>
    <AppBar/>
    <Box className='LandingScreen'>
    <Box height={'20px'} width={'100%'} sx={{bgcolor:'#F0F2F2'}}>
    </Box>
    <Stack direction={'row'} className='LandingContainer'>
<Stack direction={'column'} gap={3} className='LandingContainer1' >
<h1 className='headingStyle' style={{color:'black',textAlign:'left', fontSize:'2rem',paddingTop:'3%' , paddingLeft:'2%'}}>Welcome to Danfoss Tool</h1>
    <h3 style={{color:'black', lineHeight:'1.5',padding:'3% 10% 4% 2%',fontSize:'1rem'}}>
      Danfoss offers a broad range of products, including refrigeration and air conditioning equipment, heating controls, hydraulics, water controls, and drives for electric motors. The company also provides solutions for energy-efficient buildings, renewable energy systems, and industrial automation.
    </h3>
    <Link to="/login" style={{ textDecoration: 'none' }}>
      <StyledButton style={{ width: '40%', borderRadius: '30px',height:'55px',backgroundColor:'#ED1C2C' }}><h3 style={{color:'white'}}>Explore</h3></StyledButton>
    </Link>
</Stack>

 
<Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }} className='LandingContainer2'>
    <video controls autoPlay muted preload="auto">
      <source src="https://res.cloudinary.com/dk3mlofmi/video/upload/v1655730282/Danfoss_hnbqi5.mp4" type="video/mp4" />
    </video>
</Box>

</Stack>

    </Box>
  
<Stack direction={'row'} className='LandingFooter'>
  <a href='https://www.facebook.com/danfoss'>
    <Button >
      <FacebookIcon  />
    </Button>
  </a>
  <a href='https://www.youtube.com/user/DanfossGroup'>
    <Button>
      <YouTubeIcon className='icon2' />
    </Button>
  </a>
  <a href='https://twitter.com/danfoss'>
    <Button>
      <TwitterIcon className='icon2' />
    </Button>
  </a>
  <a href='https://www.instagram.com/danfoss_group/?hl=en'>
    <Button>
      <InstagramIcon className='icon2' />
    </Button>
  </a>
</Stack> 
    </Box>


  );
}

export default LandingScreen;



