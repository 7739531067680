

const CustomStyles2 = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'white' : 'black',
      backgroundColor: state.isFocused ? '#B0000F' : '#F2F4F7',
      padding:10,
      
    }),
    control: (provided, state) => ({
      ...provided,
      padding:'2px',
      bordeRadius:'7px',
      marginTop: "1%",
      color:'white',
      marginBottom:'2%',
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      backgroundColor:"rgba(217, 217, 217, 0.49)",
      
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#ED1C2C', // This changes the text color of the selected item
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#ED1C2C;'
  }),
    }
   export default CustomStyles2;