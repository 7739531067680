import React from "react";
import { Box, Slider, styled } from "@mui/material";

const WhiteSlider = styled(Slider)(({ theme, size }) => ({
  "& .MuiSlider-thumb": {
    color: "#5470FF",
    height: size === 'small' ? 12 : 16,
    width: size === 'small' ? 12 : 16// // Change the thumb color
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#D9D9D9", // Change the unslided area color
  },
  "& .MuiSlider-track": {
    backgroundColor: "#5470FF", // Change the filled area color
  },
}));

const BlueSlider = (props) => {
  return (
    
    <Box  className={props.className} display={ props.display === true ? 'block' : 'none'} height={props.height} width={props.width} p={props.p}>
      <WhiteSlider
        marks={props.marks}
        size={props.size}
        orientation={props.orientation}
        value={props.value}
        min={props.min}
        max={props.max}
        onChange={props.onChange}
        valueLabelDisplay={props.label === undefined ? 'off' : props.label }
      />
    </Box>
  );
};

export default BlueSlider;
