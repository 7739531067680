import React, { useEffect } from "react";
import { Grid, Box, Button } from "@mui/material";
import Select from "react-select";
import CustomCheckBox from "../../customComponent/CustomCheckBox";
import "../../CSS/Chiller.css";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  ModelAdj,
  ModelImperial,
  Modelmetric,
  ModelCurrency,
  SlideSlider3_1,
  SlideSlider1,
  SlideSlider5_1,
  ModelDegradation2,
  SlideSlider2_3,
  Slideslider3,
  ModelDegradation,
  SliderCapacity,
  SlideEffAdj,
  SlideSlider2,
} from "../../Redux/Components/Chiller/ChillerSlice";

import { ModelSelectedValue2 } from "../../Redux/ApiCall/SelectedValue2";
import { ModelSelectedItems } from "../../Redux/ApiCall/SelectedItem";
import AppBar from "../../AppBar/AppBar";

import CustomStyles from "../../customComponent/DDownStyles";
import CustomStyles2 from "../../customComponent/CustomDropDownStyles";
import HorizontalSlider from "../../customComponent/HorizontalSlider";
import BlueSlider from "../../customComponent/BlueHorizontalSlider";
import { Link } from "react-router-dom";
import { ModelSelectedValue } from "../../Redux/ApiCall/SelectedValueSlice";
import CustomSlider from "../../customComponent/Customslider";
import { getSelectedvalue } from "../../Redux/ApiCall/SelectedValueSlice";

const CompareChiller = () => {
  const { selectedValue, data } = useSelector((store) => store.selectedValue1);
  const { selectedItems, currencydata } = useSelector(
    (store) => store.selectedItems
  );
  const { selectedValue2 } = useSelector((store) => store.selectedValue2);

  const {
    slider3,
    slider2,
    slider3_1,
    degradation,
    slider1,
    slider5_1,
    slider2_3,
    Adj,
    imperial,
    metric,
    capacity,
    EffAdj,
    perKW,
    hours,
    currency,
    degradation2,
  } = useSelector((store) => store.chiller);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length === 0) {
      dispatch(getSelectedvalue());
    }
  }, []);

  const label36 = capacity;
  const slider1_5 = perKW;
  const label4 = slider1_5 / 100;
  const slider1_4 = hours;
  const label14_6 = selectedValue.Column3;
  const label14_7 = selectedValue.Column1;
  const slider5 = EffAdj;
  const label45 = slider5 / 100;
  const label3 = slider1_4;
  const label14_1 = selectedValue.Column7 + label45;
  const label14_2 = selectedValue.Column6;
  const label15_1 = 12 / (selectedValue.Column6 * 3.412).toFixed(2);
  const label12 = Math.round(
    selectedValue.Column5 * 0.28434 + capacity / 3.517
  );

  const label15 = (12 / (label14_1 * 3.412)).toFixed(2);
  const label14 = Math.round(selectedValue.Column5 + label36);
  const label10 = Math.round(label14 * 0.58 / label14_1 * label4 * label3);
  const label16 = Math.round(label10 / label4);
  const label16_1 = Math.round(label16 * 0.000718);
  const label47_4 = label14_6 === "Centrifugal Oil Free" ? 25 : 0;
  const label47_1 = label14_7 === "Air Cooled" ? 450 : 0;
  const label47 = label14_7 === "Water Cooled" ? 300 : 0;

  const label2 = label47_4 + label47_1 + label47;
  const label17 = label2 * label12 + slider3 * 300;
  const label17_1 = Math.round(label17 / label14);
  const label17_2 = Math.round(label17 / label12);
  const label30_2 = Math.round(selectedItems.Column3 * label17);
  const label20 = label10 * 10 + label17;
  //Oil degradation
  const label8 = slider2;
  const label26 = label8;
  const label32 = Math.round(label8 / 0.8);
  const label25 = (selectedItems.Column3 * label4).toFixed(2);
  const label30 = Math.round(selectedItems.Column3 * label10);
  const label30_1 = Math.round(selectedItems.Column3 * label20);
  const label40 = label8 / 100 + 1;
  const label38 = Math.round(label10 * label40);
  const label21 = label26 / 100;
  const label27 = Math.round(label10 * label21);
  const label34 = label10 + label27;

  //Box3
  const label39 = slider1;
  const label12_1 = Math.round(
    selectedValue2.Column5 * 0.28434 + label39 / 3.5
  );

  const label46 = slider5_1 / 100;
  const label14_4 = selectedValue2.Column7 + label46;
  //label14_23
  const label14_3 = selectedValue2.Column5 + label39;
  


  const label15_2 = (12 / (label14_4 * 3.412)).toFixed(2);

  const label10_1 = Math.round(label14_3 * 0.58 / label14_4 * label4 * label3);
  const label16_2 = Math.round(label10_1 / label4);
  const label16_3 = Math.round(label16_2 * 0.000718);

  const label14_8 = selectedValue2.Column3;
  const label14_9 = selectedValue2.Column1;
  const label47_6 = label14_8 === "Centrifugal Oil Free" ? 25 : 0;

  const label47_7 = label14_9 === "Water Cooled" ? 300 : 0;
  const label47_8 = label14_9 === "Air Cooled" ? 450 : 0;
  const label7 = label47_6 + label47_7 + label47_8;
  const label17_3 = label7 * label12_1 + slider3_1 * 300;


  const label17_4 = Math.round(label17_3 / label14_3);
  const label17_5 = Math.round(label17_3 / label12_1);
  const label30_5 = Math.round(selectedItems.Column3 * label17_3);
  const label20_1 = Math.round(label10_1 * 10 + label17_3);
  // Box4
  const label33 = slider2_3;
  const label26_2 = label33;
  const label32_1 = Math.round(label33 / 0.8);
  const label43 = label33 / 100 + 1;
  const label38_1 = Math.round(label10_1 * label43);
  const label30_3 = Math.round(selectedItems.Column3 * label10_1);
  const label30_4 = Math.round(selectedItems.Column3 * label20_1);
  const label21_1 = label33 / 100;
  const label27_1 = Math.round(label10_1 * label21_1);
  const label34_1 = label10_1 + label27_1;
  //ROI
  const label9 = label17_3 - label17;
  const label37 = label34 - label34_1;
  const label35 = (label9 / label37).toFixed(1);
  const label14_5 = selectedValue2.Column6;
  const label15_3 = 12 / (selectedValue2.Column6 * 3.412);

  const customFilter = (option, searchText) => {
    if (
      option.data.Column4?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Column1?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Column2?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Column3?.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
    
      <Box className="ScreenBox2">
        <AppBar
          headline={
            <Box width={"98%"} display={currency === true ? "block" : "none"}>
              {" "}
              <Select
                onFocus={false}
                className="selectStyle"
                styles={CustomStyles2}
                options={currencydata}
                value={selectedItems}
                onChange={(newValue) => dispatch(ModelSelectedItems(newValue))}
                getOptionLabel={(currency) => currency.Column2}
                placeholder={"Default USD"}
              />{" "}
            </Box>
          }
        />
        <Box
          display={"flex"}
          width={"100%"}
          flexDirection={{ md: "row", xs: "column" }}
        >
          <Box
            width={{ md: "70%", xs: "100%" }}
            display={"flex"}
            flexDirection={"column"}
          >
            <Box
              display={"flex"}
              className="TitleBox"
              flexDirection={"row"}
              justifyContent={{xs:"center",md:'flex-end'}}
              paddingRight={{ md: "10%" }}
              width={{md:'90%',xs:'100%',lg:'90%'}}
            >
              <h1 className="PageTitle">
                Competitive Analysis Tool 6.0 - Comparison
              </h1>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={{ md: "flex-end", xs: "center" }}
              paddingRight={{ md: "10%" }}
              className="custom-check-box-appbar"
            >
              <CustomCheckBox
                name={"Capp/Eff Adj"}
                checked={Adj}
                onChange={(event) => dispatch(ModelAdj(!Adj))}
              />
              <CustomCheckBox
                name={"Currency"}
                checked={currency}
                onChange={(event) => dispatch(ModelCurrency(!currency))}
              />
              <CustomCheckBox
                checked={imperial}
                name={"Imperial"}
                onChange={(event) => dispatch(ModelImperial(!imperial))}
              />
              <CustomCheckBox
                checked={metric}
                name={"Metric"}
                onChange={(event) => dispatch(Modelmetric(!metric))}
              />
            </Box>
          </Box>
          <Box width={{ md: "30%" }} display={"flex"} flexDirection={"row"} className="compare-Chiller-Roi-Box">
            <Box className="imageBox2" margin={"3% 5%"}>
              <img
                alt="X ray"
                height={"90%"}
                width={"90%"}
                style={{ objectFit: "contain" }}
                src="../images/X-RayTurboTT.png"
              />
            </Box>
            <Box
              display={"flex"}
              padding={"4% 2% 9% 2%"}
              flexDirection={"column"}
              justifyContent={'space-around'}
            >
              <h2
                style={{
                  fontWeight: "500",
                  color: "#ED1C2C",
                  width: "60px",
                  textAlign: "center",
                  
                }}
              >
                ROI
              </h2>
              <h2
                style={{
                  fontWeight: "500",
                  color: "#5470FF",
                  fontSize:'1.2rem',
                  width: "100px",
                  textAlign: "left",
                  paddingLeft:'10%'
                }}
              >
                {label35 === "-Infinity" ? "Inf" : label35} yrs
              </h2>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-around"}
              padding={"4% 2% 9% 2%"}
            >
              <Link to={"/chiller"} style={{ textDecorationColor: "#DC143C" }}>
                <h4 style={{ color: "#DC143C" }}>Chiller A</h4>
              </Link>
              <Link to={"/chillerB"} style={{ textDecorationColor: "#DC143C" }}>
                <h4 style={{ color: "#DC143C" }}> Chiller B</h4>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          className="Compare-Chiller-Box-1"
          display={"flex"}
          flexDirection={{ md: "row", xs: "column" }}
        >
          <Box
            className="Compare-Chiller-Box-2"
            width={{ md: "50%", xs: "100%" }}
            mr={'0.7%'}
            mt={{ md: "0", xs: "2%" }}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Box width={"20%"} p={"2%"} sx={{ backgroundColor: "white" }}>
                <h5>{selectedValue.Column1}</h5>
              </Box>
              <Box width={"40%"}>
                <Select
                  getOptionValue={(option) => `${option}`}
                  isSearchable={true}
                  filterOption={customFilter}
                  className="selectStyle"
                  styles={CustomStyles}
                  options={data}
                  value={selectedValue}
                  onChange={(newValue) =>
                    dispatch(ModelSelectedValue(newValue))
                  }
                  getOptionLabel={(option) => option.Column4}
                  placeholder={"Find Item"}
                />
              </Box>
              <Box width={"20%"} p={"2%"} sx={{ backgroundColor: "white" }}>
                <h5>{selectedValue.Column3}</h5>
              </Box>
            </Box>
            <Box
              alignItems={"center"}
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              
            >
              <HorizontalSlider
              p={{xl:'2%',md:'0.5%'}}
                width={"75%"}
                value={slider3}
                min={-200}
                max={100}
                onChange={(e) => dispatch(Slideslider3(e.target.value))}
              />
              <Stack
                direction={"row"}
                width={{xs:"95%",md:'80%',lg:'80%'}}
                justifyContent={"space-between"}
                bgcolor={'white'}
                p={'2%'}
              >
                <Box width={"40%"} >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                    paddingLeft={'8%'}
                    
                  >
                
                    {metric && (
                      <h6 style={{ color: "black" }}>
                        Chiller 1 Kwr - {label14 ? label14 : capacity}
                      </h6>
                    )}
                    {metric && (
                      <h6 style={{ color: "black" }}>
                        Full load - {label14_2 ? label14_2 : 0}
                      </h6>
                    )}
                    {metric && (
                      <h6 style={{ color: "black" }}>
                        IPLV Kw/Kwr - {label14_1 ? label14_1.toFixed(2) : EffAdj / 100}
                      </h6>
                    )}
                     {metric && (
                      <h6 style={{ color: "black" }}>
                        $ Per Ton - {(label30_2 / label14).toFixed(2)  === "NaN" ? 0 : (label30_2 / label14).toFixed(2) }
                      </h6>
                    )}
                  </Box>
                </Box>
                <Box
                  width={"20%"}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={0.5}
                  
                >
                  <h5 style={{ textAlign: "center", color:'#DC143C' }}>Chiller</h5>
                  <h5 style={{ textAlign: "center" }}>
                    $ {label30_2 ? label30_2 : 0}
                  </h5>
                </Box>
                <Box width={"40%"}>
                <Box
                   
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                    paddingLeft={{xs:'20%',md:'40%'}}
                  >
                    {imperial && (
                       <h6 style={{color:'black'}}>
                       Chiller TR - {Math.round(label12 ? label12 : 0)}
                     </h6>
                    )}
                     {imperial && (
                         <h6 style={{color:'black'}}>
                         Full load - {label15_1 ? label15_1.toFixed(2) : 0}
                       </h6>
                      )}
                       {imperial && (
                         <h6 style={{color:'black'}}>
                         IPLV Kw/Tr - {label15 === "NaN" ? 0 : label15}
                       </h6>
                      )}
                       {imperial && (
                         <h6 style={{color:'black'}}>
                         $ Per Kwr - {(label30_2 / label12).toFixed(2)  === "NaN" ? 0 : (label30_2 / label12).toFixed(2) }
                       </h6>
                      )}
                  </Box>
                </Box>
              </Stack>
              
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              mt={{xs:'2%',md:'2.5%',xl:'3%'}}
              gap={{ md: 5 }}
            >
              <Box
                 width={{ md: "40%",xs:'43%' }}
                display={"flex"}
                flexDirection={"column"}
                gap={{xs:1.5,md:1.5,xl:2}}
                sx={{ backgroundColor: "white" }}
                p={{ md: "2% 5%", xs: "2%" ,xl:'3% 5%'}}
              >
                <h6>Kw/Hr - {label16 ? label16 : 0}</h6>
                <h6>CO2 Emissions Tons - {label16_1 ? label16_1 : 0}</h6>
                <h6>Opex Per Annum - ${label30 ? label30 : 0}</h6>
                <h6>Ownership 10 Yrs - ${label30_1 ? label30_1 : 0}</h6>
                {degradation && (
                  <h6>New Opex With Oil/Deg - ${label38 ? label38 : 0}</h6>
                )}

                {selectedValue.Column3 !== "Centrifugal Oil Free" && (
                  <CustomCheckBox
                    name={"Add Oil Degradation"}
                    checked={degradation}
                    onChange={(event) =>
                      dispatch(ModelDegradation(!degradation))
                    }
                  />
                )}
              </Box>
              <Box
                 width={{md:"50%",xs:'52%'}}
                display={"flex"}
                flexDirection={"row"}
                p={{ md: "1%", xs: "1%" }}  
                justifyContent={'space-around'}
                sx={{ backgroundColor: "white" }}
              >
                {Adj && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                    alignItems={"center"}
                  >
                    <h6 style={{ textAlign: "center" }}> Capacity Adj</h6>
                    <BlueSlider
                      orientation={"vertical"}
                      display={Adj}
                      height={{md:"10rem",xs:'10rem',xl:'12rem'}}
                      value={capacity}
                      min={-500}
                      max={500}
                      onChange={(e) => dispatch(SliderCapacity(e.target.value))}
                    />
                  </Box>
                )}
                {Adj && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                    alignItems={"center"}
                  >
                    <h6 style={{ textAlign: "center" }}> Eff Adj</h6>
                    <BlueSlider
                      orientation={"vertical"}
                      display={Adj}
                      height={{md:"10rem",xs:'10rem',xl:'12rem'}}
                      value={EffAdj}
                      min={-500}
                      max={500}
                      onChange={(event) =>
                        dispatch(SlideEffAdj(event.target.value))
                      }
                    />
                  </Box>
                )}
                {degradation && (
                  <Box display={"flex"} flexDirection={"column"}>
                    <h6 style={{paddingLeft:'10%' }}> Oil deg</h6>
                    <h6 style={{color:'red', paddingLeft:'30%'}}>{label26} %</h6>
                    <CustomSlider
                  boxPadding={'0 0 0 20%'}
                      orientation={"vertical"}
                      display={degradation}
                      height={{md:"7.5rem",xs:'7.5rem',xl:'10rem'}}
                      min={0}
                      max={20}
                      value={slider2}
                      onChange={(e) => dispatch(SlideSlider2(e.target.value))}
                    />
                    <Stack direction={'row'} width={'100%'} >
                      <Box width={'30%'}><h6 style={{ color:'red'}}>{label32 ? label32 : 0}</h6></Box>
                      <Box width={'70%'}>
                      <h6 style={{color:'red'}}>Yrs in</h6>
                      </Box>
                    </Stack>
                    <h6 style={{color:'red'}}> Operation</h6>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="Compare-Chiller-Box-2"
            width={{ md: "50%", xs: "100%" }}
            ml={"0.7%"}
            mt={{ md: "0", xs: "2%" }}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Box width={"20%"} p={"2%"} sx={{ backgroundColor: "white" }}>
                <h5>{selectedValue2.Column1}</h5>
              </Box>
              <Box width={"40%"}>
                <Select
                  getOptionValue={(option) => `${option}`}
                  isSearchable={true}
                  filterOption={customFilter}
                  className="selectStyle"
                  styles={CustomStyles}
                  options={data}
                  value={selectedValue2}
                  onChange={(newValue) =>
                    dispatch(ModelSelectedValue2(newValue))
                  }
                  getOptionLabel={(option) => option.Column4}
                  placeholder={"Find Item"}
                />
              </Box>
              <Box width={"20%"} p={"2%"} sx={{ backgroundColor: "white" }}>
                <h5>{selectedValue2.Column3}</h5>
              </Box>
            </Box>
            <Box
              alignItems={"center"}
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
            >
              <HorizontalSlider
              p={{xl:'2%',md:'0.5%'}}
                width={"75%"}
                value={slider3_1}
                min={-200}
                max={100}
                onChange={(e) => dispatch(SlideSlider3_1(e.target.value))}
              />
                         <Stack
                direction={"row"}
                width={{xs:"95%",md:'80%',lg:'80%'}}
                justifyContent={"space-between"}
                bgcolor={'white'}
                p={'2%'}
              >
                <Box width={"40%"} >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                    paddingLeft={'8%'}
                  >
                
                    {metric && (
                      <h6 style={{ color: "black" }}>
                        Chiller 2 Kwr - {label14_3 ? label14_3 : slider1}
                      </h6>
                    )}
                    {metric && (
                      <h6 style={{ color: "black" }}>
                        Full load - {label14_5 ? label14_5 : 0}
                      </h6>
                    )}
                    {metric && (
                      <h6 style={{ color: "black" }}>
                        IPLV Kw/Kwr - {label14_4 ? label14_4.toFixed(2) : slider5_1 / 100}
                      </h6>
                    )}
                        {metric && (
                      <h6 style={{ color: "black" }}>
                        $ Per Ton - {(label30_5 / label14_3).toFixed(2)  === "NaN" ? 0 : (label30_5 / label14_3).toFixed(2) }
                      </h6>
                    )}
                  </Box>
                </Box>
                <Box
                  width={"20%"}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={0.5}
                >
                  <h5 style={{ textAlign: "center", color:'#DC143C' }}>Chiller</h5>
                  <h5 style={{ textAlign: "center" }}>
                  $ {label30_5 ? label30_5 : 0}
                  </h5>
                </Box>
                <Box width={"40%"}>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                    paddingLeft={{xs:'20%',md:'40%'}}
                  >
                    {imperial && (
                       <h6 style={{color:'black'}}>
                       Chiller TR - {Math.round(label12_1 ? label12_1 : 0)}
                     </h6>
                    )}
                     {imperial && (
                         <h6 style={{color:'black'}}>
                         Full load - {label15_3 ? label15_3.toFixed(2) : 0}
                       </h6>
                      )}
                       {imperial && (
                         <h6 style={{color:'black'}}>
                         IPLV Kw/Tr - {label15_2 === "NaN" ? 0 : label15_2}
                       </h6>
                      )}
                          {imperial && (
                         <h6 style={{color:'black'}}>
                         $ Per Kwr - {(label30_5 / label12_1).toFixed(2)  === "NaN" ? 0 : (label30_5 / label12_1).toFixed(2) }
                       </h6>
                      )}
                  </Box>
                </Box>
              </Stack>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              mt={{xs:'2.5%',md:'2.5%',xl:'3%'}}
              gap={{ md:5 }}
            >
              <Box
              className="calulationbox"
                width={{ md: "40%",xs:'43%' }}
                display={"flex"}
                flexDirection={"column"}
                gap={{xs:1.5,md:1.5,xl:2}}
                sx={{ backgroundColor: "white" }}
                p={{ md: "2% 5%", xs: "2%" ,xl:'3% 5%'}}
              >
                <h6>Kw/Hr - {label16_2 ? label16_2 : 0}</h6>
                <h6>CO2 Emissions Tons - {label16_3 ? label16_3 : 0}</h6>
                <h6>Opex Per Annum - ${label30_3 ? label30_3 : 0}</h6>
                <h6>Ownership 10 Yrs - ${label30_4 ? label30_4 : 0}</h6>
                {degradation2 && (
                  <h6>New Opex With Oil/Deg - ${label38_1 ? label38_1 : 0}</h6>
                )}

                {selectedValue2.Column3 !== "Centrifugal Oil Free" && (
                  <CustomCheckBox
                    name={"Add Oil Degradation"}
                    checked={degradation2}
                    onChange={(event) =>
                      dispatch(ModelDegradation2(!degradation2))
                    }
                  />
                )}
              </Box>
              <Box
                width={{md:"50%",xs:'52%'}}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={'space-around'}
                p={{ md: "1%", xs: "1%" }}  
                sx={{ backgroundColor: "white" }}
              >
                {Adj && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                    alignItems={"center"}
                  >
                    <h6 style={{ textAlign: "center" }}> Capacity Adj</h6>
                    <BlueSlider
                      orientation={"vertical"}
                      display={Adj}
                      height={{md:"10rem",xs:'10rem',xl:'12rem'}}
                      value={slider1}
                      min={-500}
                      max={500}
                      onChange={(e) => dispatch(SlideSlider1(e.target.value))}
                    />
                  </Box>
                )}
                {Adj && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                    alignItems={"center"}
                  >
                    <h6 style={{ textAlign: "center" }}> Eff Adj</h6>
                    <BlueSlider
                      orientation={"vertical"}
                      display={Adj}
                      height={{md:"10rem",xs:'10rem',xl:'12rem'}}
                      value={slider5_1}
                      min={-500}
                      max={500}
                      onChange={(event) =>
                        dispatch(SlideSlider5_1(event.target.value))
                      }
                    />
                  </Box>
                )}
                {degradation2 && (
                  <Box display={"flex"} flexDirection={"column"}>
                    <h6 style={{paddingLeft:'10%' }}> Oil deg</h6>
                    <h6 style={{color:'red', paddingLeft:'30%'}}>{label26_2} %</h6>
                    <CustomSlider
                           boxPadding={'0 0 0 20%'}
                      orientation={"vertical"}
                      display={degradation2}
                      height={{md:"7.5rem",xs:'7.5rem',xl:'10rem'}}
                      min={0}
                      max={20}
                      value={slider2_3}
                      onChange={(e) => dispatch(SlideSlider2_3(e.target.value))}
                    />
                     <Stack direction={'row'} width={'100%'} >
                      <Box width={'30%'}><h6 style={{ color:'red'}}>{label32_1 ? label32_1 : 0}</h6></Box>
                      <Box width={'70%'}>
                      <h6 style={{color:'red'}}>Yrs in</h6>
                      </Box>
                    </Stack>
                    <h6 style={{color:'red'}}> Operation</h6>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CompareChiller;
