
import { useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Container, Typography, Link as Linked } from '@mui/material';
import AppBar from '../../AppBar/AppBar';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Linked color="inherit" href="https://www.danfoss.com/en-au/">
                Danfoss
            </Linked>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const ResetPasswordScreen = ({ match }) => {

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const {token, uid} = useParams();
    
    const resetPasswordHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        if (password !== password2) {
            setPassword("");
            setPassword2("");
            setTimeout(() => {
                setError("")
            }, 5000);
            return setError('Password do not match')
        }
       
        try {
           
            const { data } = await axios.post(process.env.REACT_APP_API_URL_USER + `reset-password/${uid}/${token}/`,
                {
                    password, password2, uid, token
                },
                config
            );
            console.log(data)
            setSuccess(data.message);
        } catch (error) {
            console.log(error)
            setError(error.response.data.errors.password[0]);
            setTimeout(() => {
                setError("");

            }, 5000);
        }

    }

    return (
        <>
        <Box className='ScreenBox3'>
        <AppBar />
        <Container component="main" maxWidth="md">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Grid container justifyContent='center'>
                    <img src='../images/Icon.png' alt='Icon' width='200px' />
                </Grid>
                <Typography component="h1" variant="h6">
                    Reset Password
                </Typography>
                {error && <span>{error}</span>}
                    {success && <span>{success} <Link to="/login">
                        Log in
                    </Link></span>}
                <Box component='form' sx={{ mt: 3 }}>
                
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                            color='secondary'
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            color='secondary'
                                required
                                fullWidth
                                name="password"
                                label="Confirm Password"
                                type="password"
                                id="Confirmpassword"
                                value={password2}
                                onChange={e => setPassword2(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                    onClick={resetPasswordHandler}
                    color='tertiary'
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        <Typography color='primary'>Reset</Typography>
                    </Button>
                </Box>

            </Box>
            <Copyright sx={{ mt: 5 }} />

        </Container>
        </Box>
        </>
    )
}

export default ResetPasswordScreen