import React, { useEffect } from 'react';
import { Grid, Box, TextField, Typography, FormControlLabel, Checkbox, Container, Button, Avatar, Link as Linked, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { CssBaseline } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import "../../CSS/Home.css";
import AppBar from '../../AppBar/AppBar';



function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Linked color="inherit" href=" https://www.danfoss.com/en-au/">
                Danfoss
            </Linked>{'   '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



const Log = () => {


    const [email1, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState("");
    const [Remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(false);


    const history = useNavigate();
    const email = email1.toLocaleLowerCase();

    useEffect(() => {
        if (localStorage.getItem("email")) {
          setEmail(localStorage.getItem("email"));
          setRemember(true);
        }
      }, []);


      const handleSubmit = async (event) => {
        setLoading(true);
    
        event.preventDefault();
        const config = {
          Headers: {
            "Content-Type": "application/json",
          }
        }
        try {
          const {data} = await axios.post( 
            process.env.REACT_APP_API_URL_USER  + "token/", { password, email }, config);
         
          
          localStorage.setItem("authToken", data.access);
          if (Remember) {
            localStorage.setItem("email", email1);
          } else {
            localStorage.removeItem("email");
          }
          history("/home");
          setLoading(false);
        } catch (error) {
          setLoading(false);
        
          setError(error.response.data.detail + ': Please Check your detail');
          setTimeout(() => {
            setError("")
          }, 5000)
        }
      };


    return (
        <>
        <Box className='ScreenBox3'>
                <AppBar  />
                <Box height={'20px'} width={'100%'} sx={{bgcolor:'#F0F2F2'}}>
    </Box>
                    <Container component="main" maxWidth="xs">
                      
                        <Box
                            sx={{
                                marginTop: '3%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center', alignItems: 'center' }}>
                                <Grid container justifyContent='center'>
                                    <img src='/images/Icon.png' alt='Icon' width='200px' />
                                </Grid>
                                <Typography component="h1" variant="h6" pt='2%'>
                                    Welcome Back </Typography>
                                {error && <span>{error}</span>}
                                <Box component='form' onSubmit={handleSubmit} sx={{ p: '1% 5% 6% 6%' }}>
                                    <TextField color='secondary' margin='normal' fullWidth required id='email'
                                        label='Email Address' name='email' autoFocus variant='outlined' type="email" value={email1} onChange={e => setEmail(e.target.value)} />
                                    <TextField color='secondary'
                                        margin="normal"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password" />
                                    <FormControlLabel
                                        control={<Checkbox checked={Remember} onChange={e => setRemember(!Remember)} color='secondary' value="remember" />}
                                        label="Remember me"
                                    />
                                    <LoadingButton
                                        type='submit'
                                        sx={{ mt: '4%', mb: '4%' }}
                                        color='tertiary'
                                        fullWidth
                                        size="small"
                                        onClick={handleSubmit}
                                        loading={loading}
                                        variant="contained"
                                    >
                                        <Typography color='primary' >Sign In</Typography>
                                    </LoadingButton>
                                    <Stack direction="column" gap={2}>
  <Link to="/forgotpassword" style={{ textDecoration: 'none' }}>
  <h3 style={{color:'blue'}}>
      Forgot Password ?
    </h3>
  </Link>

  <Link to="/register" style={{ textDecoration: 'none' }}>
    <h3 style={{color:'blue'}}>
      Don't have an account? Sign Up
    </h3>
  </Link>
</Stack>
                             
                                    <Copyright sx={{ mt: 5 }} />
                                </Box>
                            </Box>




                        </Box>
                    </Container>
                    </Box>

        </>
    )
}

export default Log