import React from "react";
import { Box, Slider, Grid, Stack , styled} from "@mui/material";
import "../CSS/index.css";


const WhiteSlider = styled(Slider)(({ theme , size}) => ({
    "& .MuiSlider-thumb": {
      color: "#5470FF", 
      height: size === 'small' ? 12 : 17,
      width: size === 'small' ? 12 : 17// Change the thumb color
    },
    "& .MuiSlider-rail": {
      backgroundColor: "#D9D9D9", // Change the unslided area color
    },
    "& .MuiSlider-track": {
      backgroundColor: "#5470FF", // Change the filled area color
    },
  }));
  

const CustomSlider = (props) => {

    return <>
    <Stack direction={'column'} gap={1.5} width={props.boxwidth} padding={props.boxPadding}>
        <h5 style={{margin:props.TypoMargin,fontSize:'12px' ,whiteSpace:"pre-wrap"}}>{props.SliderName || null}</h5>
    <Stack direction={'row'} paddingTop={props.pad}>
        <Stack direction={'column'}spacing={0.5}>
         <Box height={props.height} className={props.className} width={props.width}  textAlign={props.textAlign || null} >
             <WhiteSlider
               m={props.m}
                 color={props.color}
                 size={props.size}
                 orientation="vertical"
                 value={props.value}
                 min={props.min}
                 max={props.max}
                onChange={props.onChange}
                 valueLabelDisplay="off"
                 marks={props.marks}
             />
        </Box>
        <Stack direction={'column'} width={'1.5rem'} spacing={0.5} display={props.bottomvalueDispley === undefined || props.bottomvalueDispley === true ? 'block' : 'none'}>
        <h6  style={{fontSize:'12px'}}>{props.BottomName || null}</h6>
         <h6 style={{color:'#ED1C2C'}}>{props.BottomValue || null}</h6>
         </Stack>
        </Stack>
        <Stack direction={'column'} marginTop={'2.5rem'} >
        <Box display={props.unitnameDisplay}>
             <h6 style={{fontSize:'12px', margin:props. margin}}>{props.unitname}</h6>
             <h6>{props.unitvalue}</h6>
         </Box>
         
            <Box>
        <h6>{props.valuename2}</h6>
        <h6>{props.value2}</h6>
            </Box>

        </Stack>
    </Stack>

       </Stack>
    </>
}


export default CustomSlider;

