

const CustomStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? 'white' : 'black',
    backgroundColor: state.isFocused ? '#B0000F' : '#F2F4F7',
    padding:10,
    
  }),
  control: (provided, state) => ({
    ...provided,
    padding:'2px',
    bordeRadius:'7px',
    marginTop: "1%",
    color:'white',
    marginBottom:'2%',
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor:"#DC143C",
    
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white', // This changes the text color of the selected item
}),
dropdownIndicator: (provided, state) => ({
  ...provided,
  color: 'white', // This changes the color of dropdown indicator
}),
  }
 export default CustomStyles;