
import React from 'react'
import { Box } from '@mui/material'

function SmallContent(props) {
  return (
    <Box  width={'80%'} zIndex={2}  display = {props.value === true ? 'block': 'none'} >
        {props.context}
    </Box>
  )
}

export default SmallContent