import React from 'react'
import { useEffect} from 'react';
import Select from 'react-select';
import { Box } from '@mui/material';
import "../../CSS/index.css";
import CustomStyles from '../../customComponent/DDownStyles';
import { useDispatch, useSelector } from 'react-redux';

import { ModelSelectedPerKwData, getPerkwData } from '../../Redux/ApiCall/SelectedPerkw';


const USDperKw = () => {

  useEffect(() => {
    if (PerKWdata.length === 0) {
    dispatch(getPerkwData());
    }
  }, []); 


    const{
      PerKWdata,
    selectedPerKW,
    } = useSelector((store)=> store.selectedPerkw);
const dispatch = useDispatch()


  return (
  
    <Box   width={'60%'} ml={'25%'} height={{xs:'350px'}}>
         <Select 
                      styles={CustomStyles}
                      options={PerKWdata}
                      value={selectedPerKW}
                      onChange={newValue => dispatch(ModelSelectedPerKwData(newValue))}
                      // onInputChange={value=>dispatch({type:'field', field:"InputValue3" , value: value})}
                      getOptionLabel={(currency) => currency.Column1}
                      placeholder={'Find Currency'} />
                      <h1 style={{fontSize:'1.2rem', marginTop:'2%',textAlign:'center'}}>GlobalPower price per KW</h1>
                      <h3 style={{textAlign:'center',marginTop:'2%'}}>Country: {selectedPerKW.Column1}</h3>
                      <h3 style={{ textAlign:'center',marginTop:'2%'}}>$perKW : {selectedPerKW.Column2}</h3>
                     
    </Box>
  
  )
}





export default USDperKw