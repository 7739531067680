import React from "react";
import { Box, Stack } from "@mui/material";
import "../../CSS/index.css";

import CustomCheckBox from "../../customComponent/CustomCheckBox";
import "../../CSS/OFCPage.css";
import { useDispatch, useSelector } from "react-redux";
import {
  ModelCheckValve,
  ModelComponents,
  ModelOilFreeMetric,
  ModelFeatures,
  SlideSlider6_26,
  SlideSlider6_22,
  SlideSlider6_23,
  SlideSlider6_24,
  SlideSlider6_25,
} from "../../Redux/Components/OFC/OFCSlice";
import TopBar from "../../AppBar/AppBar";
import AppBar from "../../AppBar/AppBar";
import WhiteCheckBox from "../../customComponent/WhiteCheckBox";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomSlider from "../../customComponent/Customslider";
import HorizontalSlider from "../../customComponent/HorizontalSlider";
import CloseIcon from '@mui/icons-material/Close';

const OFCPage = () => {
  const {
    slider6_22,
    slider6_23,
    slider6_24,
    slider6_25,
    slider6_26,
    CheckValve,
    Features,
    Components,
    OilFreeMetric,
  } = useSelector((store) => store.OFC);

  const dispatch = useDispatch();

  //Formula for Oil free Valve
  const label44_18 = slider6_23 / 100;
  const label44_19 = slider6_25;
  const label72_1 = 0.58;
  const label44_7 = slider6_24 / 100;
  const label61_4 = (12 / (label44_18 * 3.412)).toFixed(2);
  const label44_6 = slider6_22;
  const label44 = slider6_26 / 10000;
  const label44_20 = slider6_23 / 100 + label44;
  const label48_60 = Math.round(label44_6 * 3.5);
  const label71_4 = Math.round(
    label44_6 * label72_1 * label44_18 * label44_7 * label44_19
  );
  const label71_5 = Math.round(
    label44_6 * label44_20 * label72_1 * label44_7 * label44_19
  );
  const label48 = label71_4 - label71_5;

  return (
    <>
      <Box className="ScreenBox2">
        <AppBar />
        <Box display={"flex"} justifyContent={"center"} className="title-box-ofc" >
          <Box display={"flex"} bgcolor={"#ED1C2C"} gap={2} p={"0% 0.5% 0% 3%"}>
            <h2 className="PageTitle" style={{ color: "white" }}>
              Danfoss Oil - Free Check Valve
            </h2>{" "}
            {
              <WhiteCheckBox
                checked={CheckValve}
                onChange={(e) => dispatch(ModelCheckValve(!CheckValve))}
              />
            }
          </Box>
        </Box>
        <Box display={"flex"} gap={2}  className="ofc-page-box">
        <Box className="OFC-Features-Container" zIndex={Features || Components === true ? 2 : 0}>
                  {
                    Features && (<>
                      <img src="../images/Features.png" alt="features" className="image-features"/>
                      <button onClick={(event) => dispatch(ModelFeatures(!Features))} style={{position:'absolute', right:'2%'}}>  <CloseIcon style={{color:'red'}}/></button>
                      </> )
                  }
                    {
                    Components && (<>
                      <img src="../images/Components.png" alt="features" className="image-features" />
                      <button onClick={(event) => dispatch(ModelComponents(!Components))} style={{position:'absolute', left:'2%'}}> 
                      <CloseIcon style={{color:'red'}}/>
                      </button>
                      </> )
                  }
                 
                </Box>
          <Box
            className="OFC-Box-1"
            display={"flex"}
            flexDirection={{ md: "row", xs: "column" }}
          >
            <Box
              width={{ md: "20%" }}
              display={"flex"}
              flexDirection={{ md: "column", sm: "row" }}
              pt={"3%"}
            >
              <CustomCheckBox
                name={"Metric"}
                checked={OilFreeMetric}
                onChange={(event) =>
                  dispatch(ModelOilFreeMetric(!OilFreeMetric))
                }
              />

              <CustomCheckBox
                name={`Features`}
                checked={Features}
                onChange={(event) => dispatch(ModelFeatures(!Features))}
              />
              <CustomCheckBox
                name={`Components`}
                checked={Components}
                onChange={(event) => dispatch(ModelComponents(!Components))}
              />
            </Box>
            <Box
              display={"flex"}
              width={{ md: "60%" }}
              flexDirection={"row"}
              justifyContent={"center"}
              position={'relative'}
            
            >
              <img src="../images/power.png" className="power-image-tool-ofc" />
              <div className="Value1"><h5> {OilFreeMetric === true ? '12.1' : '53.8'} </h5></div>
                    <div className="Value2"><h5> {OilFreeMetric === true ? '6.7' : '44.1'} </h5></div>
                    <div className="Value3"><h5> {OilFreeMetric === true ? '34.5' : '94.1'} </h5></div>
                    <div className="Value4"><h5> {OilFreeMetric === true ? '29.4' : '84.9'} </h5></div>
            </Box>
            <Box
              display={"flex"}
              width={{ md: "20%" }}
              flexDirection={"column"}
              justifyContent={"flex-end"}

            >
              <Box width={{ md: "70%", xs: "20%" }} mt={"20px"} ml={"30%"}>
                <img
                  src="../images/Hydolic.png"
                  alt="hydrolic"
                  className="Hydrolic"
                  width={"80%"}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        
        <Box
          display={"flex"}
          flexDirection={{ md: "row", xs: "column" }}
          className="OFC-Box-2"
   
        >
            
          <Box className="OFC-Box-3" width={{ md: "50%", xs: "100%", sm:'100%' }}>
            <Box
              width={{ md: "8%" }}
              display={"flex"}
              flexDirection={"column"}
              p={"0% 2% 1% 2%"}
              bgcolor={"RGBA(236, 235, 235, 0.45)"}
              gap={1}
            >
              <Box
                className="AjustableBox"
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
              >
                <KeyboardArrowUpIcon
                  style={{ fontSize: "2rem", color: "blue" }}
                />
                <h4>
                  Plant <br /> Level
                </h4>
                <KeyboardArrowDownIcon
                  style={{ fontSize: "2rem", color: "blue" }}
                />
              </Box>
              <h4>TG520</h4>
              <h4>TG390</h4>
              <h4>TG230</h4>
            </Box>
            <Box className="OFC-Box-4" width={{ lg: "92%", sm:'100%' }}>
              <Box className="OFC-Slider-Box" display={'flex'} flexDirection={'row'} width={{md:'100%', sm:'100%'}}>
              <Box width={{md:"30%"}} display={"flex"} flexDirection={"row"}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={1}
                  justifyContent={"center"}
                  alignContent={"center"}
                >
                  <h5
                    style={{
                      fontSize: "12px",
                      whiteSpace: "pre-wrap",
                      color: "#ED1C2C",
                    }}
                  >
                    Capacity
                  </h5>
                  <HorizontalSlider
                    p={"0 0 0 15%"}
                    className={'AdjustableSlider3'}
                    BottomName={OilFreeMetric === true ? "Kwr" : "Tn"}
                    BottomValue={
                      OilFreeMetric === true
                        ? Math.round(slider6_22 * 3.5)
                        : slider6_22
                    }
                    value={slider6_22}
                    min={70}
                    max={700}
                    orientation={"vertical"}
                    onChange={(e) => dispatch(SlideSlider6_22(e.target.value))}
                  />
                  <h6 style={{ fontSize: "12px", textAlign: "center" }}>
                    {" "}
                    {OilFreeMetric === true ? "Kwr" : "Tn"}
                  </h6>
                  <h5 style={{ color: "#ED1C2C", textAlign: "center" }}>
                    {" "}
                    {OilFreeMetric === true
                      ? Math.round(slider6_22 * 3.5)
                      : slider6_22}
                  </h5>
                </Box>
                <Box

                  display={"flex"}
                  className="TT-Box"
                  flexDirection={"column"}
                  gap={0.5}
                >
                  <h5> TT700</h5>
                  <h5> TT400</h5>
                  <h5> TT300</h5>
                </Box>
              </Box>

              <CustomSlider
              className={'AdjustableSlider3'}
                SliderName={"Chiller IPLV"}
                min={25}
                max={99}
                value={slider6_23}
                onChange={(e) => dispatch(SlideSlider6_23(e.target.value))}
                BottomName={OilFreeMetric === true ? "Kw/Kw" : "Kw/Tn"}
                BottomValue={
                  OilFreeMetric === true ? label61_4 : slider6_23 / 100
                }
                boxwidth={"25%"}
                
              />
              <CustomSlider
              className={'AdjustableSlider3'}
                SliderName={"$ Per KW"}
                value={slider6_24}
                min={1}
                max={25}
                onChange={(e) => dispatch(SlideSlider6_24(e.target.value))}
                BottomName={"$per/Kw"}
                BottomValue={slider6_24 / 100}
                boxwidth={"25%"}
             
              />
              <CustomSlider
                SliderName={" Run Hours / Year"}
                value={slider6_25}
                min={1}
                max={8760}
                boxwidth={"25%"}
                onChange={(event) =>
                  dispatch(SlideSlider6_25(event.target.value))
                }
                BottomName={"Hours"}
                BottomValue={slider6_25}
                className={'AdjustableSlider3'}
              />
            </Box>
            </Box>
          </Box>
          <Box width={{ md: "50%", xs: "95%" }} className="OFC-Box-5" >
            <Box display={"flex"} flexDirection={"row"} width={"100%"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                padding={'0% 2% 0 10%'}
                width={"100%"}
              >
                <h5 style={{ fontWeight: "600" }}>Chiller Opex Per Year</h5>
                {CheckValve && (
                  <h5 style={{ fontWeight: "600" }}>Opex With OFC</h5>
                )}
                {CheckValve && (
                  <h5 style={{ fontWeight: "600" }}>OFC Savings Per Year</h5>
                )}
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                width={"10%"}
              >
                <h5 style={{ fontWeight: "600" }}>$</h5>

                {CheckValve && <h5 style={{ fontWeight: "600" }}>$</h5>}

                {CheckValve && <h5 style={{ fontWeight: "600" }}>$</h5>}
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                width={"40%"}
              >
                <h5 style={{ fontWeight: "600" }}>{label71_4}</h5>
                {CheckValve && <h5 style={{ fontWeight: "600" }}>{label71_5}</h5>}
                {CheckValve && <h5 style={{ fontWeight: "600" }}>{label48}</h5>}
              </Box>
            </Box>
            <Stack width={'100%'} display={CheckValve === true ? 'block' : 'none'} direction={'column'} className="ofc-box-6" >
            <h2 style={{color:'#ED1C2C', fontSize:'1.1rem',fontWeight:'600',textAlign:'center', marginBottom:'5%'}}>Pressure Drop</h2>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <h6 style={{fontWeight:'600'}}>Low</h6>
              <h6  style={{fontWeight:'600'}}>Mid</h6>
              <h6  style={{fontWeight:'600'}}>High</h6>
            </Box>
            <HorizontalSlider 
            value={slider6_26}
            min={-75}
            max={0}
            onChange={e => dispatch(SlideSlider6_26(e.target.value))}
            />
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <h6 style={{fontWeight:'600'}}>OFC</h6>
              <h6  style={{fontWeight:'600'}}>Swing Water</h6>
              <h6  style={{fontWeight:'600'}}>Axial Water</h6>
              <h6  style={{fontWeight:'600'}}>Piston Type</h6>
            </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default OFCPage;


