import React, { useEffect, useState } from "react";
import "../../CSS/Home.css";
import { Box, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppBar from "../../AppBar/AppBar";
import { useSelector, useDispatch } from "react-redux";
import { getUserData } from "../../Redux/ApiCall/UserData";
import { getSelectedvalue } from "../../Redux/ApiCall/SelectedValueSlice";
import { getCurrency } from "../../Redux/ApiCall/SelectedItem";
import Disclaim from "../Discliam/Disclaim";
import jwt_decode from "jwt-decode";
import CustomCard from "../../customComponent/CustomCard";


const HomeScreen = () => {
  const { first_name, UserData } = useSelector((store) => store.userinfo);

  const [notAgreed, setToAgree] = useState(true);

  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const authToken = localStorage.getItem("authToken");
  const agreed = sessionStorage.getItem("disclaimer");

  useEffect(() => {
    dispatch(getUserData());
    dispatch(getSelectedvalue());
    dispatch(getCurrency());
    if (agreed) {
      setToAgree(false);
    }
    const intervalId = setInterval(checkSessionTimeout, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleDisc = () => {
    setToAgree(false);
    sessionStorage.setItem("disclaimer", "agreed");
  };

  const checkSessionTimeout = () => {
    if (authToken) {
      const decodedToken = jwt_decode(authToken);
      const expirationTime = decodedToken.exp; // expiration time in Unix timestamp format
      const currentTime = Math.floor(Date.now() / 1000);

      console.log(`Time remaining = ${expirationTime - currentTime}`);
      if (expirationTime < currentTime) {
        setShowAlert(true);
      }
    }
  };

  const handleLogout = () => {
    setShowAlert(false);
    localStorage.removeItem("authToken");
    sessionStorage.removeItem("disclaimer");
    navigate("/login");
    window.location.reload();
  };

  const menuItems = [
    {
      name: "Chiller Analysis",
      image: ["../images/chiller.png"],
      link: "/chiller",
      details:
        "This tool is a marketing tool that gives a ROI ( Return on investment ) based on a IPLV ( Integrated part load value ) for two water chillers with selected technologies.",
    },
    {
      name: "Hydronic Tool",
      image: ["../images/HydroPic2.png", "../images/HydoPic1.png"],
      link: "/hydro",
      details:
        "This tool gives a visual display of performance when using pressure independent balancing valves combined with variable speed drives.",
    },
    {
      name: "Low Lift Tool",
      image: ["../images/LowLift1.png", "../images/LowLift2.png"],
      link: "/tool",
      details:
        "This tool demonstrates the high performance when combining ambient relief, high leaving water temperatures and Turbocor low lift application.",
    },
    {
      name: "Value Tool",
      image: ["../images/valueTool.png"],
      link: "/value",
      details:
        "This tool will help navigate and explain some of the some of the assumption in the ROI tool. For example.. plant efficiency, IPLV etc.",
    },
    {
      name: "OFC",
      image: ["../images/OFC.png"],
      link: "/ofc",
      details:
        "This tool will show the savings and feature when using an OFC (Oil-Free Check valve ) from Danfoss over current valves in the market.",
    },
  ];

  return (
    <>
      <Box className="ScreenBox2">
        <Disclaim notAgreed={notAgreed} handleDisc={handleDisc} />
        <AppBar
          headline={
            <h1 className="headingStyle userInfo">
              Welcome {first_name ? first_name : "User"}{" "}
            </h1>
          }
        />
        {showAlert && (
          <Alert severity="warning" onClose={handleLogout}>
            Your session has expired. Please log in again
            <button style={{ color: "red" }} onClick={handleLogout}>
              Logout
            </button>
          </Alert>
        )}
        <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
          <h1
            style={{
              color: "black",
              fontSize: "100%",
              fontWeight: "500",
              padding: "1% 0 0 0",
            }}
          >
            {" "}
            WELCOME TO DANFOSS HVAC TOOLS
          </h1>
        </Box>
        <Box className="HomeBox">
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            p={"0.5% 0"}
          >
            <h1
              style={{
                fontSize: "100%",
                fontWeight: "normal",
                color: "#605B5B",
              }}
            >
              Discover Our Powerful Tools, Empowering You Across HVAC Applications.
            </h1>
          </Box>
          <Box className="gapBox"></Box>
          <Box className="HomeContent">
            {menuItems.map((item) => (
              <CustomCard
                name={item.name}
                details={item.details}
                img={item.image}
                link={item.link}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomeScreen;
