import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState={
    data :[],
    selectedValue: {
        Column1: "Water Cooled",
        Column2: "Turbocor",
        Column3: "Centrifugal Oil Free",
        Column4: "TT400*2 Water Cooled",
        Column5: 1000,
        Column6: 5.59,
        Column7: 11.22,
        PowerAppsId: "RUoLfS6gtOs"
    },
    AdminSelectedValue:{
        Column1: "Water Cooled",
        Column2: "Turbocor",
        Column3: "Centrifugal Oil Free",
        Column4: "TT400*2 Water Cooled",
        Column5: 1000,
        Column6: 5.59,
        Column7: 11.22,
        PowerAppsId: "RUoLfS6gtOs"
    },
    InputValue: "",
    isloading:false
}



const url = process.env.REACT_APP_API_URL_DATA + 'powerdata/'


export const getSelectedvalue = createAsyncThunk(
    "Chiller/getPowerdata",
    async (object, { getState, rejectWithValue }) => {
      try {
        const { data } = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        });
  
        // Loop through the data array and convert string values to integers
        const modifiedData = data.map((item) => {
          return {
            ...item,
            Column5: parseFloat(item.Column5),
            Column6: parseFloat(item.Column6),
            Column7: parseFloat(item.Column7),
          };
        });
  
        return modifiedData;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  

const selectedValue = createSlice({
    name:'selectedValue',
    initialState,
    reducers:{
        ModelInput1:(state, action)=>{
            state.InputValue = action.payload
        },
        ModelSelectedValue:(state, action)=> {
            state.selectedValue = action.payload
        } ,
        ModelAdminSelectedValue:(state, action)=> {
        state.AdminSelectedValue = action.payload
    },
    },
   
    extraReducers:(builder) => {
        builder.addCase(getSelectedvalue.fulfilled, (state, { payload }) => {
            state.data = payload
            state.isloading = false;
        })}
})


export const {ModelInput1, ModelSelectedValue, ModelAdminSelectedValue} = selectedValue.actions
export default selectedValue.reducer