import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Drawers: false,
    selectedItems3:'',
    OilDegradation:false,
OilDegradationVideo:false,
GlobalPower:false,
IPLVWater:false,
IPLVAir:false,
ECWT:false,
GMSingapore:false,
PlantEff:false,
IPLVWT:false,
USDKWR:false,
USDTon:false,

DTC:false
}



const resetStateExcept = (state, excludeKeys = []) => {
    for (const key in state) {
        if (!excludeKeys.includes(key)) {
            state[key] = false;
        }
    }
};


const ValueToolSlice = createSlice({
    name:'ValueTool',
    initialState,
    reducers:{
        ModelDTC: (state, action) => {
            state.DTC = action.payload;
            resetStateExcept(state, ['DTC', 'selectedItems']);
        },
        ModelUSDTon: (state, action) => {
            state.USDTon = action.payload;
            resetStateExcept(state, ['USDTon', 'selectedItems']);
        },
        ModelUSDKWR: (state, action) => {
            state.USDKWR = action.payload;
            resetStateExcept(state, ['USDKWR', 'selectedItems']);
        },
        ModelIPLVWT:(state, action)=>{
            state.IPLVWT = action.payload;
            resetStateExcept(state, ['IPLVWT','selectedItems']);
        },
        ModelGMS:(state, action)=>{
            state.GMSingapore = action.payload;
            resetStateExcept(state, ['GMSingapore','selectedItems']);
        
        },
        ModelPlantEff:(state, action)=>{
            state.PlantEff = action.payload;
            resetStateExcept(state, ['PlantEff','selectedItems']);
        },

        ModelECWT:(state, action)=>{
            state.ECWT = action.payload;
            resetStateExcept(state, ['ECWT','selectedItems']);
        },
        ModelIPLVAir:(state, action)=>{
            state.IPLVAir = action.payload;
            resetStateExcept(state, ['IPLVAir','selectedItems']);
        },
        ModelIPLVWater:(state, action)=>{
            state.IPLVWater = action.payload;
            resetStateExcept(state, ['IPLVWater','selectedItems']);
        },
        ModelGlobalPower:(state, action)=>{
            state.GlobalPower = action.payload;
            resetStateExcept(state, ['GlobalPower','selectedItems']);

        },
        ModelOilDegradationVideo:(state, action)=>{
            state.OilDegradationVideo = action.payload;
            resetStateExcept(state, ['OilDegradationVideo','selectedItems']);

        },
        ModelOilDegradation:(state, action)=>{
            state.OilDegradation = action.payload
            resetStateExcept(state, ['OilDegradation','selectedItems']);},
        ModelDrawers:(state, action)=>{
            state.Drawers = action.payload
        },
        ModelSelecteditem3: (state, action)=>{
            state.selectedItems3 = action.payload
            
        }
    }
}
)

export const {ModelDrawers, ModelSelecteditem3, ModelDTC, ModelECWT, ModelGlobalPower, ModelIPLVAir
, ModelIPLVWT, ModelIPLVWater, ModelLCHWAir, ModelLCHWWater, ModelOilDegradation, ModelOilDegradationVideo, ModelPerformance
, ModelPlantEff, ModelUSDKWR,ModelUSDTon, ModelGMS} = ValueToolSlice.actions
export default ValueToolSlice.reducer