import React from 'react';
import { Box, Stack, Divider } from '@mui/material';
import { StyledButton } from '../customComponent/StyledButton';
import { Link} from 'react-router-dom';
import AccountMenu from '../screens/profile/ProfileScreen';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MenuAnchor from '../screens/profile/Anchor';
import HomeIcon from '@mui/icons-material/Home';
import "../AppBar/Appbar.css"

const AppBar = (props) => {
  const authToken = localStorage.getItem('authToken');

  return (
    <Box bgcolor='#ED1C2C' position={'static'}>
      <Stack direction={{ xs: 'column', sm: 'row' }} p={'0.5%'} justifyContent='space-between'>
      <Link to='/home' style={{ textDecoration: 'none' }} >
        <Stack direction='row' gap={2}  >
        <img src='../images/Icon4.png' alt='Danfoss' width='120px' />
        <Stack direction='column' p='1.2%' gap={1.1}>
            <h5 style={{color:'white'}}>ENGINEERING</h5>
            <h5 style={{color:'white'}}>TOMORROW</h5>
        </Stack>
        
        {authToken ? ('') :  <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }} ml={'auto'}>
          <MenuAnchor />
        </Box>  }
        </Stack>
        </Link>
        <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
          <h1 className='headingStyle'>{props.headline}</h1>
        </Box>

        {authToken ? (
          <Stack mt='5px' direction='row' justifyContent={'space-between'} gap={2}>
            <AccountMenu />
            <Link to='/home' style={{ textDecoration: 'none' }} >
            <StyledButton sx={{ padding: '11px', outline: 'none' }} >
              <h5 style={{ marginRight: '5%', color:'white', display: { xs: 'none' } }}>Return</h5>
              <KeyboardReturnIcon color='primary' />
            </StyledButton>
            </Link>
          </Stack>
        ) : (
          <Box>
 
            <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
          <Stack direction='row' gap={2} divider={<Divider orientation="vertical"  sx={{bgcolor:'white', height:'30px'}}/>} >
            <Link to='/landing' style={{ textDecoration: 'none' }}>
              <StyledButton sx={{ outline: 'none', borderRadius: '20px' }}>
                <HomeIcon style={{ marginRight: '10%' }} color='primary' /> Home
              </StyledButton>
            </Link>
            <Link to='/login' style={{ textDecoration: 'none' }}>
              <StyledButton sx={{ outline: 'none', borderRadius: '20px' }}>
                <LoginIcon style={{ marginRight: '10%' }} color='primary' /> Login
              </StyledButton>
            </Link>
            <Link to='/register' style={{ textDecoration: 'none' }}>
              <StyledButton sx={{ outline: 'none', borderRadius: '20px' }}>
                <HowToRegIcon style={{ marginRight: '10%' }} color='primary' /> Register
              </StyledButton>
            </Link>
            <a href='https://www.danfoss.com/en-au/' style={{ textDecoration: 'none' }}>
              <StyledButton sx={{ outline: 'none', borderRadius: '20px' }}>
                <InfoIcon style={{ marginRight: '10%' }} color='primary' /> About
              </StyledButton>
            </a>
          </Stack>
          </Box>
          </Box>
        )}
        <Box height='100%' width='100%' sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
          {props.headline}
        </Box>
     
      </Stack>
    </Box>
  );
};

export default AppBar;
