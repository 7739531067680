import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';

export default function MenuAnchor() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLoginIcon() {
    navigate('/login');
    setAnchorEl(null); // Close the menu after navigation
  }

  function handleInfoIcon() {
    window.location.href = 'https://www.danfoss.com/en-au/';
    setAnchorEl(null); // Close the menu after navigation
  }

  function handleRegisterIcon() {
    navigate('/register');
    setAnchorEl(null); // Close the menu after navigation
  }

  // Check if the menu should be closed when navigating between pages
  React.useEffect(() => {
    setAnchorEl(null);
  }, [location.pathname]);

  return (
    <>
      <Box sx={{  alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {open ? <CloseIcon sx={{ color: 'white', fontSize: '2rem' }} /> : <MenuIcon sx={{ color: 'white', fontSize: '2rem' }} />}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            },
            backgroundColor: "#ffffff",
            borderRadius: 8,
            width: 200,
            padding: "8px 0",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleLoginIcon}>
          <LoginIcon sx={{ pr: '20px', fontSize:'1.5rem' }} /> Login
        </MenuItem>

        <MenuItem onClick={handleRegisterIcon}>
          <HowToRegIcon sx={{ pr: '20px', fontSize:'1.5rem' }} /> Sign Up
        </MenuItem>

        <MenuItem onClick={handleInfoIcon}>
          <InfoIcon sx={{ pr: '20px', fontSize:'1.5rem' }} /> About Us
        </MenuItem>

      </Menu>
    </>
  );
}
