import React, { useEffect } from "react";
import { Grid, Box, Button } from "@mui/material";
import Select from "react-select";
import CustomCheckBox from "../../customComponent/CustomCheckBox";
import "../../CSS/Chiller.css";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  ModelAdj,
  ModelImperial,
  Modelmetric,
  ModelCurrency,
  SliderHours,
  SliderPerkw,
  SlideSlider3_1,
  SlideSlider1,
  SlideSlider5_1,
  ModelDegradation2,
  SlideSlider2_3,
} from "../../Redux/Components/Chiller/ChillerSlice";

import { ModelSelectedValue2 } from "../../Redux/ApiCall/SelectedValue2";
import { ModelSelectedItems } from "../../Redux/ApiCall/SelectedItem";
import AppBar from "../../AppBar/AppBar";

import CustomStyles from "../../customComponent/DDownStyles";
import CustomStyles2 from "../../customComponent/CustomDropDownStyles";
import HorizontalSlider from "../../customComponent/HorizontalSlider";
import BlueSlider from "../../customComponent/BlueHorizontalSlider";
import { Link } from "react-router-dom";
import { getSelectedvalue } from "../../Redux/ApiCall/SelectedValueSlice";

const ChillerB = () => {
  const { selectedValue, data } = useSelector((store) => store.selectedValue1);
  const { selectedItems, currencydata } = useSelector(
    (store) => store.selectedItems
  );
  const { selectedValue2 } = useSelector((store) => store.selectedValue2);

  const {
    slider3,
    slider2,
    slider3_1,
    slider1,
    slider5_1,
    slider2_3,
    Adj,
    imperial,
    metric,
    capacity,
    EffAdj,
    perKW,
    hours,
    currency,
    degradation2,
  } = useSelector((store) => store.chiller);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length === 0) {
      dispatch(getSelectedvalue());
    }
  }, []);

  const slider1_5 = perKW;
  const label4 = slider1_5 / 100;
  const slider1_4 = hours;
  const label14_6 = selectedValue.Column3;
  const label14_7 = selectedValue.Column1;
  const slider5 = EffAdj;
  const label45 = slider5 / 100;
  const label3 = slider1_4;
  const label14_1 = selectedValue.Column7 + label45;
  const label12 = Math.round(
    selectedValue.Column5 * 0.28434 + capacity / 3.517
  );

  const label15 = (12 / (label14_1 * 3.412)).toFixed(2);

  const label10 = Math.round(label12 * 0.58 * label15 * label4 * label3);
  const label47_4 = label14_6 === "Centrifugal Oil Free" ? 25 : 0;
  const label47_1 = label14_7 === "Air Cooled" ? 450 : 0;
  const label47 = label14_7 === "Water Cooled" ? 300 : 0;
  const label2 = label47_4 + label47_1 + label47;
  const label17 = label2 * label12 + slider3 * 300;
  //Oil degradation
  const label8 = slider2;
  const label26 = label8;
  const label25 = (selectedItems.Column3 * label4).toFixed(2);
  const label21 = label26 / 100;
  const label27 = Math.round(label10 * label21);
  const label34 = label10 + label27;

  //Box3
  const label39 = slider1;
  const label12_1 = Math.round(
    selectedValue2.Column5 * 0.28434 + label39 / 3.5
  );

  const label14_3 = selectedValue2.Column5 + label39;

  const label46 = slider5_1 / 100;
  const label14_4 = selectedValue2.Column7 + label46;

  const label15_2 = (12 / (label14_4 * 3.412)).toFixed(2);

  const label10_1 = Math.round(
    ((label14_3 * 0.58) / label14_4) * label4 * label3
  );

  const label16_2 = Math.round(label10_1 / label4);
  const label16_3 = Math.round(label16_2 * 0.000718);

  const label14_8 = selectedValue2.Column3;
  const label14_9 = selectedValue2.Column1;
  const label47_6 = label14_8 === "Centrifugal Oil Free" ? 25 : 0;

  const label47_7 = label14_9 === "Water Cooled" ? 300 : 0;
  const label47_8 = label14_9 === "Air Cooled" ? 450 : 0;
  const label7 = label47_6 + label47_7 + label47_8;
  const label17_3 = label7 * label12_1 + slider3_1 * 300;

  const label17_4 = Math.round(label17_3 / label14_3);
  const label17_5 = Math.round(label17_3 / label12_1);
  const label30_5 = Math.round(selectedItems.Column3 * label17_3);
  const label20_1 = Math.round(label10_1 * 10 + label17_3);
  // Box4
  const label33 = slider2_3;
  const label26_2 = label33;
  const label32_1 = Math.round(label33 / 0.8);
  const label43 = label33 / 100 + 1;
  const label38_1 = Math.round(label10_1 * label43);
  const label30_3 = Math.round(selectedItems.Column3 * label10_1);
  const label30_4 = Math.round(selectedItems.Column3 * label20_1);
  const label21_1 = label33 / 100;
  const label27_1 = Math.round(label10_1 * label21_1);
  const label34_1 = label10_1 + label27_1;
  //ROI
  const label9 = label17_3 - label17;
  const label37 = label34 - label34_1;
  const label35 = (label9 / label37).toFixed(1);
  const label14_5 = selectedValue2.Column6;
  const label15_3 = 12 / (selectedValue2.Column6 * 3.412);


  const customFilter = (option, searchText) => {
    if (
      option.data.Column4?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Column1?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Column2?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Column3?.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Box className="ScreenBox2">
        <AppBar
          headline={
            <Box width={"98%"} display={currency === true ? "block" : "none"}>
              {" "}
              <Select
                onFocus={false}
                className="selectStyle"
                styles={CustomStyles2}
                options={currencydata}
                value={selectedItems}
                onChange={(newValue) => dispatch(ModelSelectedItems(newValue))}
                getOptionLabel={(currency) => currency.Column2}
                placeholder={"Default USD"}
              />{" "}
            </Box>
          }
        />
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
        >
          <Box
            className="TitleBox"
            width={{ xs: "100%", md: "80%" }}
            ml={{ xs: 0, md: "1%" }}
            pl={{ xs: 0, md: "14%" }}
          >
            {" "}
            <h1 className="PageTitle">
              Competitive Analysis Tool 6.0 - Chiller B
            </h1>
          </Box>
          <Box
            width={{ xs: "100%", md: "20%" }}
            pt={{ xs: "2%", md: "2%" }}
            justifyContent={"center"}
            textAlign={"center"}
          >
            {" "}
            <Link
              to={"/comparechiller"}
              style={{ textDecorationColor: "#DC143C" }}
            >
              <h3 style={{ color: "#DC143C" }}>Compare Two Models</h3>
            </Link>
          </Box>
        </Box>
        <Box className="ChillerBox">
          <Box display={{ xs: "block", sm: "block", md: "none", lg: "none" }}>
            <Stack direction={"row"} justifyContent={"center"}>
              <CustomCheckBox
                name={"Capp/Eff Adj"}
                checked={Adj}
                onChange={(event) => dispatch(ModelAdj(!Adj))}
              />
              <CustomCheckBox
                name={"Currency"}
                checked={currency}
                onChange={(event) => dispatch(ModelCurrency(!currency))}
              />
              <CustomCheckBox
                checked={imperial}
                name={"Imperial"}
                onChange={(event) => dispatch(ModelImperial(!imperial))}
              />
              <CustomCheckBox
                checked={metric}
                name={"Metric"}
                onChange={(event) => dispatch(Modelmetric(!metric))}
              />
            </Stack>
          </Box>

          <Stack direction={"row"} justifyContent={"space-between"}>
            <Box width={{ xs: "50%", sm: "40%", md: "230px" }}>
              <Select
                getOptionValue={(option) => `${option}`}
                isSearchable={true}
                filterOption={customFilter}
                className="selectStyle"
                styles={CustomStyles}
                options={data}
                value={selectedValue2}
                onChange={(newValue) => dispatch(ModelSelectedValue2(newValue))}
                getOptionLabel={(option) => option.Column4}
                placeholder={"Find Item"}
              />
            </Box>
            <Box display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
              <Stack direction={"row"}>
                <CustomCheckBox
                  name={"Capp/Eff Adj"}
                  checked={Adj}
                  onChange={(event) => dispatch(ModelAdj(!Adj))}
                />
                <CustomCheckBox
                  name={"Currency"}
                  checked={currency}
                  onChange={(event) => dispatch(ModelCurrency(!currency))}
                />
                <CustomCheckBox
                  checked={imperial}
                  name={"Imperial"}
                  onChange={(event) => dispatch(ModelImperial(!imperial))}
                />
                <CustomCheckBox
                  checked={metric}
                  name={"Metric"}
                  onChange={(event) => dispatch(Modelmetric(!metric))}
                />
              </Stack>
            </Box>

            <Box
              width={{ xs: "50%", sm: "230px" }}
              mt={{ md: "0.4%", xs: "0.8%" }}
            >
              <Link to={"/chiller"}>
                <Button
                  sx={{ backgroundColor: "#D9D9D97D" }}
                  className="NavButton"
                >
                  {" "}
                  <h3 style={{ color: "#ED1C2C" }}>Go to Chiller A</h3>
                </Button>
              </Link>
            </Box>
          </Stack>
          <Grid container className="ChillerBoxA">
            <Grid md={4} sm={12} xs={12}>
              <Stack direction={"column"}>
                <Box className="ChillerSlider" >
                  <HorizontalSlider
                    value={slider3_1}
                    min={-200}
                    max={100}
                    onChange={(e) => dispatch(SlideSlider3_1(e.target.value))}
                  />
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    p={"0 4%"}
                    width={"90%"}
                  >
                    <h5 style={{ width: "33%", textAlign: "start" }}>
                      {label17_4 ? label17_4 : 0}
                    </h5>
                    <h5 style={{ width: "33%", textAlign: "center" }}>
                      $ Chiller
                    </h5>
                    <h5 style={{ width: "33%", textAlign: "end" }}>
                      {label17_5 ? label17_5 : 0}
                    </h5>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    pt={"1%"}
                    width={"100%"}
                  >
                    <h5 style={{ color: "#DC143C", width: "30%" }}>
                      $ Per/Kwr
                    </h5>
                    <h5 style={{ width: "30%", textAlign: "center" }}>
                      {label30_5 ? label30_5 : 0}
                    </h5>
                    <h5
                      style={{
                        color: "#DC143C",
                        width: "30%",
                        textAlign: "end",
                      }}
                    >
                      $ Per/Ton
                    </h5>
                  </Stack>
                </Box>

                <Box className="SliderBox2">
                  {Adj && (
                    <div className="sliderItem">
                      <h3 style={{ textAlign: "center" }}>Capacity Adj</h3>
                      <Box className="gapBox"></Box>
                      <BlueSlider
                        value={slider1}
                        display={Adj}
                        min={-500}
                        max={500}
                        onChange={(e) => dispatch(SlideSlider1(e.target.value))}
                      />
                    </div>
                  )}
                  {Adj && (
                    <div className="sliderItem">
                      <h3 style={{ textAlign: "center" }}>Efficiency Adj</h3>
                      <Box className="gapBox"></Box>
                      <BlueSlider
                        display={Adj}
                        value={slider5_1}
                        min={-500}
                        max={500}
                        onChange={(event) =>
                          dispatch(SlideSlider5_1(event.target.value))
                        }
                      />
                    </div>
                  )}
                  {degradation2 && (
                    <div className="sliderItem">
                      <h3 style={{ textAlign: "center" }}>Oil degradation</h3>
                      <Box className="gapBox"></Box>
                      <BlueSlider
                        display={degradation2}
                        min={0}
                        max={20}
                        value={slider2_3}
                        onChange={(e) =>
                          dispatch(SlideSlider2_3(e.target.value))
                        }
                      />
                    </div>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid md={3} xs={12} sm={6} m={{ xs: "2% 0 1% 0", md: "0%" }}>
              <Box className="chillerBox3">
              
                  <h5 className="resultitem">Kw/Hr - {label16_2 ? label16_2 : 0}</h5>
                  <h5 className="resultitem">CO2 Emissions Tons - {label16_3 ? label16_3 : 0}</h5>
                  <h5 className="resultitem">Opex Per Annum - ${label10_1 ? label10_1 : 0}</h5>
                  <h5 className="resultitem">
                    Total Cost of Ownership 10 Years - $
                    {label30_4 ? label30_4 : 0}
                  </h5>
               
              </Box>
              <Box
                className="chillerBox4"
                display={
                  selectedValue2.Column3 === "Centrifugal Oil Free"
                    ? "none"
                    : "block"
                }
              >
                
               <div className="resultitem"> <CustomCheckBox
                    name={"Add Oil Degradation"}
                    checked={degradation2}
                    onChange={(event) =>
                      dispatch(ModelDegradation2(!degradation2))
                    }
                  /></div>
                  {degradation2 &&(
                     <h3 className="resultitem">Oil Deg - {label26_2}% </h3>
                  )}
                   {degradation2 &&(
                     <h3 className="resultitem">
                     Years of Operation - {label32_1 ? label32_1 : 0} years{" "}
                   </h3>
                  )}
                    {degradation2 &&(
                      <h3>
                      New Opex With Oil Degradation - $
                      {label38_1 ? label38_1 : 0}
                    </h3>
                  )}
                
              </Box>
            </Grid>
            <Grid md={3} sm={6} xs={12}>
              <Stack direction={"column"} gap={1.1} className="chillerBox5">
                <Box className="imageBox">
                  <img
                    alt="X-ray"
                    height={"100%"}
                    width={"100%"}
                    style={{ objectFit: "contain" }}
                    src="../images/X-RayTurboTT.png"
                  />
                </Box>
                <Stack
                  direction={"row"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <h2
                    style={{
                      fontWeight: "500",
                      color: "#ED1C2C",
                      width: "100px",
                      textAlign: "center",
                    }}
                  >
                    ROI
                  </h2>
                  <h2
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "500",
                      color: "#5470FF",
                      width: "120px",
                      textAlign: "center",
                    }}
                  >
                    {label35 === "-Infinity" ? "Inf" : label35} yrs
                  </h2>
                </Stack>
                <Stack direction={"row"} spacing={5.5}>
                  <h3 style={{ textDecoration: "underline" }}>
                    {selectedValue2.Column1}
                  </h3>
                  <h3 style={{ textDecoration: "underline" }}>
                    {" "}
                    {selectedValue2.Column3}
                  </h3>
                </Stack>
              </Stack>

              <Grid container spacing={2} pt={"2%"} textAlign={"center"}>
                <Grid item xs={6} md={5} sm={6} lg={6}>
                  <Stack
                    direction={"column"}
                    display={metric === true ? "block" : "none"}
                    className="chillerBox6_1"
                  >
                    <h5 className="resultitem" >
                      Chiller 2 Kwr <br /> {label14_3 ? label14_3 : slider1}{" "}
                    </h5>
                    <Box className="gapBox" ></Box>
                    <h5 className="resultitem">
                      Full load <br /> {label14_5 ? label14_5 : 0}{" "}
                    </h5>
                    <Box className="gapBox" ></Box>
                    <h5 className="resultitem">
                      IPLV Kw/Kwr <br />{" "}
                      {label14_4 ? label14_4.toFixed(2) : slider5_1 / 100}{" "}
                    </h5>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={5} sm={6} lg={6}>
                  <Stack
                    direction={"column"}
          
                    display={imperial === true ? "block" : "none"}
                    className="chillerBox6_1"
                  >
                    <h5 className="resultitem" >
                      Chiller TR <br /> {Math.round(label12_1 ? label12_1 : 0)}{" "}
                    </h5>
                    <Box className="gapBox" ></Box>
                    <h5 className="resultitem" >
                      Full load <br /> {label15_3 ? label15_3.toFixed(2) : 0}{" "}
                    </h5>
                    <Box className="gapBox" ></Box>
                    <h5 className="resultitem" >
                      IPLV Kw/Tr <br /> {label15_2 === "NaN" ? 0 : label15_2}{" "}
                    </h5>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid md={2} xs={12} sm={12} m={{ xs: "2% 0", md: "0%" }}>
              <Box className="chillerBox7">
                <Stack direction={"column"} gap={2} textAlign={"center"}>
                  <Box display={{ xs: "none", sm: "none", md: "block" }}>
                    <BlueSlider
                      value={hours}
                      display={true}
                      orientation={"vertical"}
                      className={'AdjustableSlider'}
                      min={-1}
                      max={8760}
                      onChange={(e) => dispatch(SliderHours(e.target.value))}
                    />
                  </Box>
                  <Box display={{ xs: "block", sm: "block", md: "none" }}>
                    <BlueSlider
                      value={hours}
                      display={true}
                    
                      orientation={"horizontal"}
                      min={-1}
                      max={8760}
                      onChange={(e) => dispatch(SliderHours(e.target.value))}
                    />
                  </Box>

                  <h5 style={{ color: "#ED1C2C" }}>{hours}</h5>
                  <h5>Operating Hours per year</h5>
                </Stack>
                <Stack direction={"column"} gap={2} textAlign={"center"}>
                  <Box display={{ xs: "none", sm: "none", md: "block" }}>
                    <BlueSlider
                      display={true}
                      className={'AdjustableSlider'}
                      orientation="vertical"
                      min={0}
                      max={35}
                      value={perKW}
                      onChange={(e) => dispatch(SliderPerkw(e.target.value))}
                   
                    />
                  </Box>
                  <Box display={{ xs: "block", sm: "block", md: "none" }}>
                    <BlueSlider
                      display={true}
                      orientation="horizontal"
                      min={0}
                      max={35}
                      value={perKW}
                      onChange={(e) => dispatch(SliderPerkw(e.target.value))}
                    />
                  </Box>

                  <h5 style={{ color: "#ED1C2C" }}>
                    {label25 === "NaN" ? "0" : label25}
                  </h5>
                  <h5>$ Per KW/hr</h5>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ChillerB;
