import axios from 'axios';
import { Box, Container, Grid,  Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import AppBar from '../../AppBar/AppBar';
import { Link } from 'react-router-dom';

export default function SignUp() {
  const [first_name, setfname] = useState('');
  const [last_name, setLname] = useState('');
  const [email1, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');

  const handleSubmit = async (event) => {
    const email = email1.toLocaleLowerCase();
   
    setLoading(true);
    event.preventDefault();

    if (password !== password2) {
      setPassword('');
      setPassword2('');
      setError('Passwords do not match');
      setTimeout(() => {
        setError('');
      }, 5000);
      setLoading(false);
      return;
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    

    try {
      const { data } = await axios.post(process.env.REACT_APP_API_URL_USER + 'register/', {
        first_name,
        last_name,
        password,
        email,
        password2,
      }, config);
      localStorage.setItem('authToken', data.access);
      setSuccess(data.message);
      setLoading(false);
    } catch (error) {
 
      setError(error.response.request.responseText);
      setTimeout(() => {
        setError('');
        setSuccess('');
        setLoading(false);
      }, 5000);
    }
  };

  return (
    <>
    <Box className='ScreenBox3'>

   
        <AppBar />
        <Box height={'20px'} width={'100%'} sx={{bgcolor:'#F0F2F2'}}>
    </Box>
        <Container   maxWidth='xs' >
          <Box
            sx={{
                marginTop: '3%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid container justifyContent='center'>
              <img src='../images/Icon.png' alt='Icon' width='200px' />
            </Grid>
            <Typography component='h1' variant='h5'>
              Sign up
            </Typography>
            {success && (
              <Typography variant='body1' color='success'>
                {success} <Link to='/login'>Login</Link>
              </Typography>
            )}
            {error && <Typography variant='body1' color='error'>{error}</Typography>}
            <Box component='form' onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    color='secondary'
                    value={first_name}
                    onChange={(e) => setfname(e.target.value)}
                    autoComplete='given-name'
                    name='first_name'
                    fullWidth
                    id='first_name'
                    label='First Name'
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color='secondary'
                    value={last_name}
                    onChange={(e) => setLname(e.target.value)}
                    autoComplete='given-name'
                    name='lname'
                    required
                    fullWidth
                    id='last_name'
                    label='Last Name'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color='secondary'
                    type='email'
                    value={email1}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    fullWidth
                    id='email'
                    label='Email Address'
                    name='email'
                    autoComplete='email'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color='secondary'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    fullWidth
                    name='password'
                    label='Password'
                    type='password'
                    id='password'
                    autoComplete='new-password'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color='secondary'
                    required
                    fullWidth
                    name='password'
                    label='Confirm Password'
                    type='password'
                    id='Confirmpassword'
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                </Grid>
           
              </Grid>
              <LoadingButton
                sx={{ mt: 3, mb: 2 }}
                color='tertiary'
                fullWidth
                size='small'
                onClick={handleSubmit}
                loading={loading}
                variant='contained'
              >
               <h4 style={{color:'white'}}>Sign Up</h4> 
              </LoadingButton>
              <Box>
  <Link to='/login' style={{ color: 'black', textDecoration: 'none' }}>
  <h3 style={{color:'blue'}}>
      Don't have an account? Sign Up
    </h3>
  </Link>
</Box>
            
            </Box>
          </Box>
        </Container>
        </Box>

    </>
  );
}
