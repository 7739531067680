import React, {useEffect, useState} from "react";

import { Routes, Route,} from "react-router-dom";
import { theme } from "./Theme";
import { ThemeProvider} from "@mui/material";
import ForgotPasswordScreen from './screens/forgotScreen/ForgotPasswordScreen';
import ResetPasswordScreen from "./screens/resetScreen/ResetPasswordScreen";
import Log from "./screens/logRegister/Log";
import SignUp from "./screens/logRegister/Register";
import HomeScreen from "./screens/homeScreen/HomeScreen";

import PrivatRoute from "./components/routing/PrivatRoute";
import ChillerPage from "./components/Chiller/ChillerPage";
import HydroPage from "./components/Hydronoc Plant performance/HydroPage";
import ToolBox from "./components/Low Lift Tools/ToolBox";
import Valuepage from "./components/ValueTool/Valuepage";
import OFCPage from "./components/OFC/OFCpage";
import LandingScreen from "./screens/landing/LandingScreen";
import PowerAppForm from "./Admin/PowerAppForm";
import CurrencyForm from "./Admin/Currency";
import PerKwForm from "./Admin/PerKWForm";
import AdminPage from "./Admin/AdminPage";
import ChillerB from "./components/Chiller/ChillerB";

import {  useSelector } from "react-redux";
import CompareChiller from "./components/Chiller/CompareChiller";



function App() {

  const { isadmin} = useSelector((store)=>store.userinfo)
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  
  
  return (
    <ThemeProvider theme={theme}>
      
    <Routes>

    <Route exact path="/landing" element={<LandingScreen/>} />
     <Route exact path="/home" element={<PrivatRoute>
       <HomeScreen />
     </PrivatRoute>} />
      <Route exact path="/" element={isAuthenticated ? <HomeScreen/> : <LandingScreen/>} />
      <Route exact path="/login" element={<Log/>} />
      <Route exact path="/admin" element={ isadmin ?  <AdminPage /> : null} />
      <Route exact path="/currency" element={<CurrencyForm/>} />
      <Route exact path="/perkw" element={<PerKwForm/>} />
      <Route exact path="/power" element={<PowerAppForm/>} />
      <Route exact path="/register" element={<SignUp />} />
      <Route exact path="/forgotpassword" element={<ForgotPasswordScreen />} />
      <Route exact path="/passwordreset/:uid/:token" element={<ResetPasswordScreen />} />
      <Route exact path="/chiller" element={<PrivatRoute>
       <ChillerPage/>
     </PrivatRoute>} />
     <Route exact path="/chillerB" element={<PrivatRoute>
       <ChillerB/>
     </PrivatRoute>} />
     <Route exact path="/comparechiller" element={<PrivatRoute>
      <CompareChiller/>
     </PrivatRoute>
     }/>
      
     <Route exact path="/hydro" element={<PrivatRoute>
       <HydroPage/>
     </PrivatRoute>} />

     <Route exact path="/tool" element={<PrivatRoute>
       <ToolBox/>
     </PrivatRoute>} />
     <Route exact path="/ofc" element={<PrivatRoute>
       <OFCPage />
     </PrivatRoute>} />
     <Route exact path="/value" element={<PrivatRoute>
       <Valuepage/>
       </PrivatRoute>} />
    </Routes>
  </ThemeProvider>

  );
}

export {App};
