import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    Hydrocapacity: 500,
    HydroPerHour: 5000,
      Hydrochiller:60,
      HydroPerKW: 14,
      slider6_1 :100,
      slider6 :100,
      slider7_1 : 100,
      slider6_18: 0,
      slider6_19:0,
      slider6_21:0,
      slider6_20:0,
      slider7_3:100,
      PIBCV:true,
      Hppmetric:true,
      VSDPumps:true,
  
}


const HydroSlice = createSlice({
    name:'Hydro',
    initialState,
    reducers:{
        HydroCapacitySlider:(state, action)=>{
            state.Hydrocapacity = action.payload
        },
        HydroPerKWSlider:(state, action)=>{
            state.HydroPerKW = action.payload
        },
        HydroChillerSlider:(state, action)=>{
            state.Hydrochiller = action.payload
        },
        HydroPerHourSlider:(state, action)=>{
            state.HydroPerHour = action.payload
        },
        SlideSlider6_1:(state,action)=>{
            state.slider6_1 = action.payload
        },
        SlideSlider6:(state,action)=>{
            state.slider6 = action.payload
        },
        SlideSlider7_1:(state,action)=>{
            state.slider7_1 = action.payload
        },
        SlideSlider6_18:(state,action)=>{
            state.slider6_18 = action.payload
        },
        SlideSlider6_19:(state,action)=>{
            state.slider6_19 = action.payload
        },
        SlideSlider6_21:(state,action)=>{
            state.slider6_21 = action.payload
        },        
        SlideSlider6_20:(state,action)=>{
            state.slider6_20 = action.payload
        },
        SlideSlider7_3:(state,action)=>{
            state.slider7_3 = action.payload
        },
        ModelPIBCV:(state,action)=>{
            state.PIBCV = action.payload
            
        },
        ModelVSDPUMP:(state,action)=>{
            state.VSDPumps = action.payload
        },
   
        ModelHPPMetric:(state,action)=>{
            state.Hppmetric = action.payload
        }

    }
})
export const {HydroCapacitySlider, HydroPerKWSlider, HydroChillerSlider,HydroPerHourSlider, SlideSlider6, SlideSlider6_1, SlideSlider6_18, SlideSlider6_19, SlideSlider6_20,SlideSlider6_21, SlideSlider7_1 ,SlideSlider7_3, ModelPIBCV , ModelVSDPUMP
, ModelGMS, ModelHPPMetric, 
} = HydroSlice.actions

export default HydroSlice.reducer;