import React from "react";
import { FormControlLabel } from "@material-ui/core";

import "../CSS/index.css";

import { Checkbox, styled } from "@mui/material";

const CheckBoxStyled = styled(Checkbox)(({theme})=>({
    color:'white',
    '&.Mui-checked':{
        color:'white'
    }
}))
const WhiteCheckBox =(props)=>{
    return (
        <>
        <FormControlLabel  value="end" className="props.className" control={<CheckBoxStyled checked={props.checked} onChange={props.onChange}/>} 
        label={<h6 style={{color:'black'}}>{props.name}</h6>} />
        </>
    )
}

export default WhiteCheckBox;